import Vue from 'vue'
import Vuex from 'vuex'
import moment from 'moment'
import * as administration from '@/store/modules/administration.js'
import * as users from '@/store/modules/users.js'
import * as usability from '@/store/modules/usability.js'
import * as projects from '@/store/modules/projects.js'
import * as companies from '@/store/modules/companies.js'
import * as bookings from '@/store/modules/bookings.js'
import * as messages from '@/store/modules/messages.js'
import * as sales from '@/store/modules/sales.js'
import * as quotes from '@/store/modules/quotes.js'

Vue.use(Vuex)

export default new Vuex.Store({
     state: {
          years: [],
     },
     modules: {
          administration,
          users,
          usability,
          projects,
          companies,
          bookings,
          messages,
          sales,
          quotes,
     },
     actions: {
          genYears({ state }) {
               for (let i = 2018; i <= Number(moment().format('YYYY')); i++) {
                    state.years.push(i)
               }
          },
          init_Data({ dispatch }) {
               dispatch('genYears')
               dispatch('users/get_UserInfo', {})
               dispatch('companies/get_Companies', {})
               dispatch('companies/get_Contacts', {})
               dispatch('projects/get_Projects', {})
               dispatch('projects/get_Tasks', {})
               dispatch('projects/get_Times', {})
               dispatch('bookings/get_Bookings', {})
               dispatch('bookings/get_BookingAccounts', {})
               dispatch('bookings/get_InvoicePositions', {})
               dispatch('sales/get_Sales', {})
               dispatch('sales/get_Channels', {})
               dispatch('quotes/get_Quotes', {})
               dispatch('quotes/get_Positions', {})
          },
     },
})
