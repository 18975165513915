<template>
     <div id="v_menu">
          <div
               id="v_addBtn"
               class="v_Btn"
               @click="toggleMenu"
               :class="toggleStatus ? 'active' : ''"
          >
               <font-awesome-icon icon="plus" />
          </div>
          <div
               class="v_navEl v_Btn"
               v-for="(btn, index) in buttons"
               :title="btn.title"
               :key="index"
               :class="[menuClass, btn.status]"
               :style="
                    toggleStatus
                         ? 'transform: translate(' +
                           btn.xPos +
                           'px , ' +
                           btn.yPos +
                           'px )'
                         : ''
               "
               @click="selectMenuPoint(btn.value)"
          >
               <font-awesome-icon :icon="btn.icon" />
          </div>
     </div>
</template>

<script>
export default {
     name: 'add-nav',
     props: {
          btnVals: {
               type: Array,
          },
          options: {
               type: Object,
               Default: {},
          },
     },
     data() {
          return {
               toggleStatus: false,
               buttons: [],
               clientWidth: 45,
               clientHeight: 45,
          }
     },
     computed: {
          elemCount() {
               return this.buttons.length
          },
          stepSize() {
               return Number(this.arcSize / this.elemCount)
          },
          startAngle() {
               if (this.options.startAngle != undefined) {
                    return Number(
                         (Math.PI / 180) * this.options.startAngle - Math.PI
                    )
               } else {
                    return Number(Math.PI / 180 - Math.PI)
               }
          },
          arcSize() {
               if (this.options.arcSize !== undefined) {
                    return Number((Math.PI / 180) * this.options.arcSize)
               } else {
                    return Number((Math.PI / 180) * 360)
               }
          },
          distance() {
               if (this.options.distance != undefined) {
                    return this.options.distance
               } else {
                    return 80
               }
          },
          menuClass() {
               let mClass = ''
               if (this.toggleStatus) {
                    mClass = 'active'
               } else {
                    mClass = ''
               }
               return mClass
          },
     },
     watch: {
          btnVals() {
               this.addPositionVals()
          },
     },
     methods: {
          addPositionVals() {
               this.buttons = [...this.btnVals]
               let elemCounter = 0
               this.buttons.forEach((e) => {
                    e.xPos = (
                         Math.sin(
                              2 * Math.PI -
                                   (this.startAngle +
                                        this.stepSize * elemCounter)
                         ) *
                              this.distance -
                         this.clientWidth / 2
                    ).toFixed(0)
                    e.elemCounter = elemCounter
                    e.yPos = (
                         Math.cos(
                              2 * Math.PI -
                                   (this.startAngle +
                                        this.stepSize * elemCounter)
                         ) *
                              this.distance -
                         this.clientHeight / 2
                    ).toFixed(0)
                    elemCounter++
               })
          },
          toggleMenu() {
               if (this.toggleStatus) {
                    this.toggleStatus = false
               } else {
                    this.toggleStatus = true
               }
          },
          selectMenuPoint(point) {
               this.toggleStatus = false
               this.$emit('menupoint', point)
          },
     },
     created() {
          this.addPositionVals()
     },
}
</script>

<style lang="sass">
@import '@/assets/SASS/main.sass'
$darkcolor: #454545
$secondarycolor: #67caac
$shadowcolor: lighten($secondarycolor, 25%)
#v_menu
    width: auto
    height: auto
    font-size: 1.2rem
    position: relative
    .v_Btn
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)
        border-radius: 50%
        svg
            positon: absolute
            position: absolute
            top: 50%
            left: 50%
            transform: translate(-50%, -50%)
    .v_navEl
        z-index: 50
        background-color: $secondarycolor
        color: $secondarycolor
        font-size: 0.8rem
        border: 2px solid $secondarycolor
        width: 0
        height: 0
        transition: 0.6s
        &.puls
            animation-name: light
            animation-duration: 1s
            animation-iteration-count: infinite
        &.active
            color: white
            width: 37px
            height: 37px
            box-shadow: 0 0 10px 2px $shadowcolor
            transition: 0.6s
            transition-timing-function: ease-out
            &:hover
                background-color: white
                color: $secondarycolor
                transition: 0.6s
                transform: scale(2)
        &.blocked
            background-color: lighten($lightgrey, 15%)
            border: 2px solid lighten($lightgrey, 15%)
            color: lighten($lightgrey, 5%)
            box-shadow: none
            &:hover
                background-color: lighten($lightgrey, 15%)
                color: lighten($lightgrey, 5%)
    #v_addBtn
        z-index: 100
        background-color: white
        color: $darkcolor
        border: 2px solid $darkcolor
        transition: 0.5s
        width: 50px
        height: 50px
        svg
            transition: 0.5s
        &:hover
            background-color: $darkcolor
            color: white
            transition: 0.5s
        &.active
            svg
                transition: 0.5s
                transform: rotate(45deg) translate(-80%, -5%)
                transform-origin: 50% 50%
</style>
