<template>
     <div id="statusbar">
          <!-- <div
            class="newMessages"
            @click="toggleMessagesMenu"
        ></div> -->
          <div
               :title="name"
               class="profilimg"
               @click="toggleProfilMenu"
               :style="'background-image: url(' + img + ')'"
          ></div>
          <transition name="down">
               <div class="profilmenu" v-show="profilMenu === 'open'">
                    <div class="close" @click="toggleProfilMenu">
                         <font-awesome-icon icon="times" />
                    </div>
                    <ul>
                         <router-link
                              v-for="(menu, index) in profmenu"
                              :key="index"
                              :to="menu.link"
                         >
                              <li>
                                   <font-awesome-icon
                                        :icon="menu.icon"
                                        class="mr-1"
                                   />
                                   {{ menu.title }}
                              </li>
                         </router-link>
                         <li @click="logout">
                              <font-awesome-icon
                                   icon="power-off"
                                   class="mr-1"
                              />
                              {{ $t('logout') }}
                         </li>
                    </ul>
               </div>
          </transition>
     </div>
</template>

<script>
import defaultImg from '@/assets/images/dummy.png'
export default {
     name: 'status-bar',
     data() {
          return {
               img: defaultImg,
               profilMenu: 'closed',
               profmenu: [
                    {
                         title: this.$t('profile'),
                         icon: 'user',
                         link: '/profil',
                    },
                    {
                         title: this.$t('settings'),
                         icon: 'sliders-h',
                         link: '/settings',
                    },
               ],
          }
     },
     computed: {
          name() {
               return (
                    this.$store.state.users.user.firstname +
                    ' ' +
                    this.$store.state.users.user.lastname
               )
          },
     },
     methods: {
          toggleProfilMenu() {
               this.profilMenu == 'closed'
                    ? (this.profilMenu = 'open')
                    : (this.profilMenu = 'closed')
          },
          logout() {
               this.$store.dispatch('users/logout')
               this.toggleProfilMenu()
          },
     },
}
</script>

<style lang="sass">
@import '@/assets/SASS/main.sass'
#statusbar
    position: relative
    .profilimg
        border-radius: 50%
        width: 50px
        height: 50px
        background-position: center center
        background-size: 100% auto
        margin: 1.5rem 1.5rem auto auto
        padding: 4px
        border: 3px solid lighten($lightgrey, 20%)
        &:hover
            border: 3px solid lighten($primarycolor, 10%)
    .profilmenu
        background-color: lighten($primarycolor, 15%)
        @include roundcorner
        padding: 0
        border: 1px solid lighten($primarycolor, 30%)
        z-index: 300
        position: absolute
        top: 72px
        right: 65px
        a
            &:hover
                text-decoration: none
        &:hover .close
            display: block
            transition: 0.8s
        .close
            display: none
            background-color: white
            @include round
            font-size: 1rem
            position: absolute
            top: -8px
            right: -8px
            z-index: 500
            border: 1px solid lighten($lightgrey, 20%)
            width: 2.1rem
            height: 2.1rem
            opacity: 1
            color: $lightgrey
            transition: 0.5s
            &:hover
                color: darken($lightgrey, 20%)
                border: 1px solid lighten($lightgrey, 10%)
                transition: 0.5s
            svg
                position: absolute
                top: 50%
                right: 18%
                transform: translate(-50%, -50%)
        ul
            @include noUL
            li
                color: white
                font-size: 0.9rem
                border-bottom: 1px solid white
                padding: 15px 50px
                text-transform: uppercase
                text-align: center
                transition: 0.5s
                cursor: pointer
                a
                    color: white
                    &.router-link-active
                        color: darken($primarycolor, 15%)
                &:last-of-type
                    border-bottom: none
                    font-weight: bold
                &:hover
                    background-color: white
                    color: $primarycolor
                    transition: 0.5s
</style>
