import dbService from '@/services/dbService.js'
import basicFunctions from '@/services/basicFunctions.js'

export const namespaced = true

export const state = {
     projectsdata: [],
     tasksdata: [],
     timesdata: [],
     projectstatus: [
          'To Do',
          'Waiting for Data',
          'In Progress',
          'On Hold',
          'Waiting for Release',
          'Completed',
          'Invoiced',
          'Cancelled',
     ],
     taskstatus: ['To Do', 'In Progress', 'In Review', 'Done'],
     projects: [],
     timesheetdata: [],
}

export const mutations = {
     FILL_PROJECTSDATA(state, projects) {
          state.projectsdata = projects
     },
     FILL_PROJECTS(state, projects) {
          state.projects = projects
     },
     FILL_TASKSDATA(state, tasks) {
          state.tasksdata = tasks
     },
     FILL_TIMESDATA(state, times) {
          state.timesdata = times
     },
     FILL_TIMESHEETDATA(state, data) {
          state.timesheetdata = data
     },
     CLEAR_PROJECTS(state) {
          state.projectsdata = []
          state.projects = []
     },
     CLEAR_TASKS(state) {
          state.tasksdata = []
     },
     CLEAR_TIMES(state) {
          state.timesdata = []
     },
}

export const actions = {
     get_Projects({ commit }, data) {
          data.query = 'getProjects'
          return dbService
               .runQuery(data)
               .then((response) => {
                    if (response.status == 200) {
                         commit('FILL_PROJECTSDATA', response.data)
                         commit('FILL_PROJECTS', response.data)
                    }
               })
               .catch((error) => {
                    console.log(error)
               })
     },
     get_Tasks({ commit }, data) {
          data.query = 'getTasks'
          return dbService
               .runQuery(data)
               .then((response) => {
                    if (response.status == 200) {
                         commit('FILL_TASKSDATA', response.data)
                    }
               })
               .catch((error) => {
                    console.log(error)
               })
     },
     get_Times({ commit }, data) {
          data.query = 'getTimes'
          return dbService
               .runQuery(data)
               .then((response) => {
                    if (response.status == 200) {
                         commit('FILL_TIMESDATA', response.data)
                    }
               })
               .catch((error) => {
                    console.log(error)
               })
     },
     get_TimeSheetData({ commit }, data) {
          data.query = 'getTimeSheetData'
          return dbService
               .runQuery(data)
               .then((response) => {
                    if (response.status == 200) {
                         commit('FILL_TIMESHEETDATA', response.data)
                    }
               })
               .catch((error) => {
                    console.log(error)
               })
     },
     get_All({ dispatch }) {
          dispatch('get_Projects', {})
          dispatch('get_Tasks', {})
          dispatch('get_Times', {})
     },
     add_Project({ dispatch, commit }, data) {
          data.ID = basicFunctions.generate_token(10)
          data.query = 'addProject'
          return dbService
               .runQuery(data)
               .then((response) => {
                    if (response.status != 200) {
                         commit(
                              'messages/ADD_MESSAGE',
                              {
                                   text: 'Sorry! Something went wront, so we could not save the project. Please try it again.',
                                   icon: 'info',
                                   color: 'red',
                                   time: 5000,
                              },
                              { root: true }
                         )
                    } else {
                         dispatch('get_Projects', {})
                    }
               })
               .catch((error) => {
                    console.log(error)
               })
     },
     update_Project({ dispatch, commit }, data) {
          data.query = 'updateProject'
          return dbService
               .runQuery(data)
               .then((response) => {
                    if (response.status != 200) {
                         commit(
                              'messages/ADD_MESSAGE',
                              {
                                   text: 'Sorry! Something went wront, so we could not save the project. Please try it again.',
                                   icon: 'info',
                                   color: 'red',
                                   time: 5000,
                              },
                              { root: true }
                         )
                         return 'FAILED'
                    } else {
                         dispatch('get_All', {})
                         commit(
                              'messages/ADD_MESSAGE',
                              {
                                   text: 'Project saved sucessful!',
                                   icon: 'check',
                                   color: 'green',
                                   time: 5000,
                              },
                              { root: true }
                         )
                         return 'OK'
                    }
               })
               .catch((error) => {
                    console.log(error)
               })
     },
     add_Task({ dispatch, commit }, data) {
          data.query = 'addTask'
          return dbService
               .runQuery(data)
               .then((response) => {
                    if (response.status != 200) {
                         commit(
                              'messages/ADD_MESSAGE',
                              {
                                   text: 'Sorry! The task could not be saved. Please try it again!',
                                   icon: 'info',
                                   color: 'red',
                                   time: 5000,
                              },
                              { root: true }
                         )
                    } else {
                         commit(
                              'messages/ADD_MESSAGE',
                              {
                                   text: 'New task saved sucessful!',
                                   icon: 'check',
                                   color: 'green',
                                   time: 5000,
                              },
                              { root: true }
                         )
                         dispatch('get_All', {})
                    }
               })
               .catch((error) => {
                    console.log(error)
               })
     },
     update_TaskStatus({ commit, dispatch }, data) {
          data.query = 'updateTaskStatus'
          return dbService
               .runQuery(data)
               .then((response) => {
                    if (response.status != 200) {
                         commit(
                              'messages/ADD_MESSAGE',
                              {
                                   text: 'Sorry! The task status could not be saved. Please try it again!',
                                   icon: 'info',
                                   color: 'red',
                                   time: 5000,
                              },
                              { root: true }
                         )
                    } else {
                         dispatch('get_All', {})
                    }
               })
               .catch((error) => {
                    console.log(error)
               })
     },
     update_Task({ commit, dispatch }, data) {
          data.query = 'updateTask'
          return dbService
               .runQuery(data)
               .then((response) => {
                    if (response.status != 200) {
                         commit(
                              'messages/ADD_MESSAGE',
                              {
                                   text:
                                        'Sorry! ' +
                                        data.title +
                                        ' could not be saved. Please try it again!',
                                   icon: 'info',
                                   color: 'red',
                                   time: 5000,
                              },
                              { root: true }
                         )
                         return 'FAILED'
                    } else {
                         dispatch('get_All', {})
                         commit(
                              'messages/ADD_MESSAGE',
                              {
                                   text: data.title + ' saved sucessful!',
                                   icon: 'check',
                                   color: 'green',
                                   time: 5000,
                              },
                              { root: true }
                         )
                         return 'OK'
                    }
               })
               .catch((error) => {
                    console.log(error)
               })
     },
     update_Time({ commit, dispatch }, data) {
          data.query = 'updateTime'
          return dbService
               .runQuery(data)
               .then((response) => {
                    if (response.status != 200) {
                         commit(
                              'messages/ADD_MESSAGE',
                              {
                                   text: 'Sorry! The time data could not be saved. Please try it again!',
                                   icon: 'info',
                                   color: 'red',
                                   time: 5000,
                              },
                              { root: true }
                         )
                         return 'FAILED'
                    } else {
                         dispatch('get_All', {})
                         commit(
                              'messages/ADD_MESSAGE',
                              {
                                   text: 'Time saved sucessful!',
                                   icon: 'check',
                                   color: 'green',
                                   time: 5000,
                              },
                              { root: true }
                         )
                         return 'OK'
                    }
               })
               .catch((error) => {
                    console.log(error)
               })
     },
}

export const getters = {
     activeProjects: (state) => {
          let projects = []
          if (state.projectsdata) {
               projects = state.projectsdata.filter(
                    (p) =>
                         p.status != 'Completed' &&
                         p.status != 'Invoiced' &&
                         p.status != 'Cancelled'
               )
          }
          return projects
     },
     activeProjectsColor: (state) => {
          let projects = []
          if (state.projectsdata) {
               state.projectsdata.forEach((p) => {
                    if (
                         p.status != 'Completed' &&
                         p.status != 'Invoiced' &&
                         p.status != 'Cancelled'
                    ) {
                         projects.push({
                              html:
                                   "<span style='background-color: #666'>" +
                                   p.title +
                                   '</span>',
                              value: p.ID,
                              disabled: false,
                         })
                    }
               })
          }
          console.log(projects)
          return projects
     },
     foundProjects: (state) => (searchterm) => {
          let projects = []
          if (state.projectsdata) {
               let fields = ['title', 'customer', 'status']
               searchterm = searchterm.toLowerCase().replace(' ', '')
               projects = state.projectsdata.filter((p) => {
                    let found = false
                    fields.forEach((f) => {
                         found =
                              found ||
                              p[f]
                                   .toLowerCase()
                                   .replace(' ', '')
                                   .includes(searchterm)
                    })
                    return found
               })
          }
          return projects
     },
     projectList: (state) => {
          let projectlist = []
          if (state.projectsdata) {
               state.projectsdata.forEach((p) => {
                    projectlist.push({ value: p.ID, text: p.title })
               })
               //projectlist.unshift({ text: 'none', value: '' })
          }
          return projectlist
     },
     activeTaskList: (state) => {
          let tasklist = []
          if (state.tasksdata) {
               state.tasksdata.forEach((t) => {
                    if (
                         t.projectstatus != 'Completed' &&
                         t.projectstatus != 'Invoiced' &&
                         t.status == 'In Progress'
                    ) {
                         tasklist.push({
                              value: t.ID,
                              text:
                                   'TASK: ' +
                                   t.title +
                                   ' - PROJECT: ' +
                                   t.project,
                         })
                    }
               })
          }
          return tasklist
     },
     activeProjectList: (state, getters) => {
          let activeprojectlist = []
          getters.activeProjects.forEach((p) => {
               activeprojectlist.push({ value: p.ID, text: p.title })
          })
          return activeprojectlist
     },
     taskList: (state) => {
          let tasklist = []
          if (state.tasksdata) {
               state.tasksdata.forEach((t) => {
                    let project = state.projectsdata.find(
                         (p) => p.ID == t.projectid
                    )
                    tasklist.push({
                         value: t.ID,
                         text: t.title + ' - (' + project.title + ')',
                         status: t.status,
                    })
               })
          }
          return tasklist
     },
     project: (state) => (ID) => {
          let project = []
          if (state.projectsdata) {
               project = state.projectsdata.find((p) => p.ID == ID)
          }
          return project
     },
     task: (state) => (ID) => {
          let task = []
          if (state.tasksdata) {
               task = state.tasksdata.find((t) => t.ID == ID)
          }
          return task
     },
     projecttasks: (state) => (ID) => {
          let projects = []
          if (state.tasksdata) {
               projects = state.tasksdata.filter((pt) => pt.projectid == ID)
          }
          return projects
     },
     projecttask: (state) => (ID) => {
          let task = []
          if (state.tasksdata) {
               task = state.tasksdata.find((pt) => pt.ID == ID)
          }
          return task
     },
     projecttasktimes: (state) => (ID) => {
          let times = []
          if (state.timesdata) {
               times = state.timesdata.filter((ptt) => ptt.projecttaskid == ID)
          }
          return times
     },
     projecttasktime: (state) => (ID) => {
          let time = []
          if (state.timesdata) {
               time = state.timesdata.find((ptt) => ptt.ID == ID)
          }
          return time
     },
     companyprojects: (state) => (ID) => {
          let projects = []
          if (state.projectsdata) {
               projects = state.projectsdata.filter(
                    (p) => p.customerid == ID || p.subcustomerid == ID
               )
          }
          return projects
     },
     tasksOfActiveProjects: (state) => {
          let projects = []
          if (state.tasksdata) {
               projects = state.tasksdata.filter(
                    (p) =>
                         p.projectstatus != 'Completed' &&
                         p.projectstatus != 'Invoiced'
               )
          }
          return projects
     },
     projects: (state) => {
          let projects = []
          if (state.projectsdata) {
               projects = state.projectsdata
          }
          return projects
     },
     timesheet: (state) => {
          let ts = []
          if (state.timesheetdata) {
               ts = state.timesheetdata
          }
          return ts
     },
     events: (state) => {
          let events = []
          if (state.tasksdata && state.timesdata) {
               state.timesdata.forEach((e) => {
                    state.tasksdata.forEach((t) => {
                         if (e.projecttaskid == t.ID) {
                              let bgC = '#67caac'
                              if (!t.projectid) {
                                   bgC = '#373737'
                              }
                              events.push({
                                   title: t.title,
                                   start: e.startdate + ' ' + e.starttime,
                                   end: e.enddate + ' ' + e.endtime,
                                   backgroundColor: bgC,
                                   borderColor: '#FFFFFF',
                              })
                         }
                    })
               })
          }
          return events
     },
}
