import dbService from '@/services/dbService.js'

export const namespaced = true

export const state = {
     companiesdata: {},
     companies: [],
     contactsdata: [],
     lastSavedContact: '',
}

export const mutations = {
     FILL_COMPANIESDATA(state, data) {
          state.companiesdata = data
     },
     FILL_COMPANIES(state, data) {
          state.companies = data
     },
     FILL_CONTACTSDATA(state, data) {
          state.contactsdata = data
     },
     SET_LASTSAVEDCONTACT(state, data) {
          state.lastSavedContact = data
     },
     CLEAR_COMPANIES(state) {
          state.companiesdata = []
          state.companies = []
     },
     CLEAR_CONTACTS(state) {
          state.contactsdata = []
     },
}

export const actions = {
     get_Companies({ commit }, data) {
          data.query = 'getCompanies'
          return dbService
               .runQuery(data)
               .then((response) => {
                    if (
                         response.status == 200 &&
                         response.data != 'Not found'
                    ) {
                         commit('FILL_COMPANIESDATA', response.data)
                         commit('FILL_COMPANIES', response.data)
                    }
               })
               .catch((error) => {
                    console.log(error)
               })
     },
     get_Contacts({ commit }, data) {
          data.query = 'getContacts'
          return dbService
               .runQuery(data)
               .then((response) => {
                    if (
                         response.status == 200 &&
                         response.data != 'Not found'
                    ) {
                         commit('FILL_CONTACTSDATA', response.data)
                    }
               })
               .catch((error) => {
                    console.log(error)
               })
     },
     add_Company({ dispatch, commit }, data) {
          data.query = 'addCompany'
          return dbService
               .runQuery(data)
               .then((response) => {
                    if (response.status != 200) {
                         commit(
                              'messages/ADD_MESSAGE',
                              {
                                   text: 'Sorry! The company could not be saved. Please try it again!',
                                   icon: 'info',
                                   color: 'red',
                                   time: 5000,
                              },
                              { root: true }
                         )
                    } else {
                         commit(
                              'messages/ADD_MESSAGE',
                              {
                                   text: 'New company saved sucessful!',
                                   icon: 'check',
                                   color: 'green',
                                   time: 5000,
                              },
                              { root: true }
                         )
                         dispatch('get_Companies', {})
                    }
               })
               .catch((error) => {
                    console.log(error)
               })
     },
     add_Contact({ dispatch, commit }, data) {
          data.query = 'addContact'
          return dbService
               .runQuery(data)
               .then((response) => {
                    if (response.status != 200) {
                         commit(
                              'messages/ADD_MESSAGE',
                              {
                                   text: 'Sorry! The contact could not be saved. Please try it again!',
                                   icon: 'info',
                                   color: 'red',
                                   time: 5000,
                              },
                              { root: true }
                         )
                    } else {
                         commit(
                              'messages/ADD_MESSAGE',
                              {
                                   text: 'New contact saved sucessful!',
                                   icon: 'check',
                                   color: 'green',
                                   time: 5000,
                              },
                              { root: true }
                         )
                         commit('SET_LASTSAVEDCONTACT', response.data)
                         dispatch('get_Contacts', {})
                         dispatch('get_Companies', {})
                    }
               })
               .catch((error) => {
                    console.log(error)
               })
     },
     update_Contact({ dispatch, commit }, data) {
          data.query = 'updateContact'
          return dbService
               .runQuery(data)
               .then((response) => {
                    if (response.status != 200) {
                         commit(
                              'messages/ADD_MESSAGE',
                              {
                                   text: 'Sorry! Something went wront, so we could not save the contact. Please try it again.',
                                   icon: 'info',
                                   color: 'red',
                                   time: 5000,
                              },
                              { root: true }
                         )
                         return 'FAILED'
                    } else {
                         commit(
                              'messages/ADD_MESSAGE',
                              {
                                   text: 'Contact saved sucessful!',
                                   icon: 'check',
                                   color: 'green',
                                   time: 5000,
                              },
                              { root: true }
                         )
                         dispatch('get_Contacts', {})
                         dispatch('get_Companies', {})
                         return 'OK'
                    }
               })
               .catch((error) => {
                    console.log(error)
               })
     },
     update_Company({ dispatch, commit }, data) {
          data.query = 'updateCompany'
          data.color = data.color.replace('#', '')
          //console.log(data)
          return dbService
               .runQuery(data)
               .then((response) => {
                    if (response.status != 200) {
                         commit(
                              'messages/ADD_MESSAGE',
                              {
                                   text: 'Sorry! Something went wront, so we could not save the company. Please try it again.',
                                   icon: 'info',
                                   color: 'red',
                                   time: 5000,
                              },
                              { root: true }
                         )
                         return 'FAILED'
                    } else {
                         dispatch('init_Data', '', { root: true })
                         commit(
                              'messages/ADD_MESSAGE',
                              {
                                   text: 'Company saved sucessful!',
                                   icon: 'check',
                                   color: 'green',
                                   time: 5000,
                              },
                              { root: true }
                         )
                         return 'OK'
                    }
               })
               .catch((error) => {
                    console.log(error)
               })
     },
}

export const getters = {
     companyList: (state) => {
          let companylist = []
          if (state.companiesdata) {
               let city = ''
               state.companiesdata.forEach((c) => {
                    if (c.city) {
                         city = ' (' + c.city + ')'
                    } else {
                         city = ''
                    }
                    companylist.push({ value: c.ID, text: c.company + city })
               })
          }
          return companylist
     },
     company: (state) => (ID) => {
          return state.companiesdata.find((c) => c.ID == ID)
     },
     contactList: (state) => (ID) => {
          let contactlist = []
          if (state.contactsdata) {
               state.contactsdata.forEach((c) => {
                    let cL = {
                         value: c.ID,
                         text: (c.firstname + ' ' + c.lastname).trim(),
                    }
                    if (ID) {
                         if (c.companyid == ID) {
                              contactlist.push(cL)
                         }
                    } else {
                         contactlist.push(cL)
                    }
               })
          }
          return contactlist
     },
     contact: (state) => (ID) => {
          return state.contactsdata.find((c) => c.ID == ID)
     },
     contactLine: (state) => (ID) => {
          let contact = state.contactsdata.find((c) => c.ID == ID)
          return contact.firstname + ' ' + contact.lastname
     },
     contacts: (state) => (ID) => {
          let contacts = []
          if (state.contactsdata) {
               contacts = state.contactsdata.filter((c) => c.companyid == ID)
          }
          return contacts
     },
     foundCompanies: (state) => (searchterm) => {
          let companies = []
          if (searchterm) {
               let fields = ['company', 'city', 'country', 'postalcode']
               searchterm = searchterm.toLowerCase().replace(' ', '')
               companies = state.companiesdata.filter((c) => {
                    let found = false
                    fields.forEach((f) => {
                         found =
                              found ||
                              c[f]
                                   .toLowerCase()
                                   .replace(' ', '')
                                   .includes(searchterm)
                    })
                    return found
               })
          } else {
               companies = state.companiesdata
          }
          return companies
     },
     foundContacts: (state) => (searchterm) => {
          let contacts = []
          if (searchterm) {
               let fields = [
                    'company',
                    'firstname',
                    'lastname',
                    'email',
                    'phone',
                    'mobil',
                    'position',
                    'city',
               ]
               searchterm = searchterm.toLowerCase().replace(' ', '')
               contacts = state.contactsdata.filter((c) => {
                    let found = false
                    fields.forEach((f) => {
                         found =
                              found ||
                              c[f]
                                   .toLowerCase()
                                   .replace(' ', '')
                                   .includes(searchterm)
                    })
                    return found
               })
          } else {
               contacts = state.contactsdata
          }

          return contacts
     },
}
