import Vue from 'vue'
import VueI18n from 'vue-i18n'
import store from '@/store'

Vue.use(VueI18n)

const messages = {
     en: {
          starth1: 'Welcome to freelanceStudio, the new management application for freelancers',
          starth2: 'Just register for free and start managing your <b>customers</b>, <b>projects</b>, <b>tasks</b> and <b>working hours</b> to improve your business',
          save: 'Save',
          saving: 'Saving...',
          saved: 'Saved',
          error: 'Error',
          settings: 'Settings',
          currency: 'Currency',
          language: 'Language',
          profile: 'Profile',
          logout: 'Logout',
          dashboard: 'Dashboard',
          period: 'Period',
          date: 'Date',
          day: 'day',
          week: 'week',
          month: 'month',
          year: 'year',
          hour: 'hour',
          hours: 'hours',
          minute: 'minute',
          minutes: 'minutes',
          second: 'second',
          seconds: 'seconds',
          effort: 'according to effort',
          request: 'on request',
          free: 'for free',
          mon: 'Mon',
          tue: 'Tue',
          wed: 'Wed',
          thu: 'Thu',
          fri: 'Fri',
          sat: 'Sat',
          sun: 'Sun',
          monday: 'Monday',
          tuesday: 'Tuesday',
          wednesday: 'Wednesday',
          thursday: 'Thursday',
          friday: 'Friday',
          saturday: 'Saturday',
          sunday: 'Sunday',
          profit: 'Profit',
          wh: 'working hours',
          taskboard: 'Taskboard',
          todo: 'To Do',
          inprogress: 'In Progress',
          inreview: 'In Review',
          done: 'Done',
          project: 'Project',
          projects: 'Projects',
          cards: 'Cards',
          list: 'List',
          projectsfound: 'project(s) found',
          quotesfound: 'quote(s) found',
          searchprojects: 'Search in projects...',
          searchquotes: 'Search in quotes...',
          bookingsfound: 'bookings(s) found',
          searchbookings: 'Search in bookings...',
          company: 'Company',
          companies: 'Companies',
          searchcompanies: 'Search in companies...',
          companiesfound: 'company(s) found',
          contact: 'Contact',
          contacts: 'Contacts',
          searchcontacts: 'Search in contacts...',
          contactsfound: 'contact(s) found',
          close: 'Close',
          details: 'Details',
          street: 'Street',
          city: 'City',
          postalcode: 'Postalcode',
          country: 'Country',
          mr: 'Mr.',
          mrs: 'Mrs.',
          firstname: 'Firstname',
          lastname: 'Lastname',
          position: 'Position',
          phone: 'Phone',
          mobile: 'Mobile',
          distance: 'Distance',
          drivingtime: 'Driving time',
          orderdate: 'Orderdate',
          duedate: 'Duedate',
          title: 'Title',
          customer: 'Customer',
          subcustomer: 'Subcustomer',
          customers: 'Customers',
          rate: 'Rate',
          task: 'Task',
          tasks: 'Tasks',
          taskdescription: 'Describe your task here...',
          status: 'Status',
          description: 'Description',
          describeproject: 'Describe your project here...',
          type: 'Type',
          start: 'Start',
          end: 'End',
          time: 'Time',
          times: 'Times',
          starttime: 'Start time',
          charge: 'Charge',
          nocharge: 'Free of charge',
          homeoffice: 'Home-Office',
          onsitecust: 'On-site customer',
          onsitesubcust: 'On-site subcustomer',
          step: 'Step',
          cust: 'Customer',
          tracktime: 'Track time',
          analyse: 'Analyse',
          person: 'Person',
          accessdata: 'Access data',
          password: 'Password',
          password2: 'Password 2',
          passwordr: 'Password repeat',
          introduction: 'Introduction',
          introtext:
               "Hi {firstname}! Let's start working effectively with freelanceStudio by following these little steps:",
          introductiontext: 'Introductiontext',
          addcompany: 'New Company',
          addproject: 'New Project',
          addquote: 'New Quote',
          addcontact: 'New Contact',
          addbooking: 'New Booking',
          addtask: 'New Task',
          addlead: 'New Lead',
          taxrate: 'VAT',
          shr: 'Standard hourly rate',
          perhour: 'per hour',
          invoucedatenumber: 'Invoice date / -number',
          companyproject: 'Company / Project',
          companyprojectinfo: 'Company / Project / Info',
          info: 'Info',
          payinfo: 'Payinfo',
          booking: 'Booking',
          amounts: 'Amounts',
          amount: 'Amount',
          income: 'Income',
          outgoing: 'Outgoing',
          gross: 'gross',
          tax: 'Tax',
          paymethod: 'Paymethod',
          invoicedate: 'Invoice Date',
          invoiceno: 'Invoice Number',
          bookaccount: 'Book Account',
          bookingmonth: 'Booking Month',
          netamount: 'Net Amount',
          paydate: 'Paydate',
          bookingtype: 'Booking Type',
          overview: 'Overview',
          bookings: 'Bookings',
          incominginvoices: 'Incoming Invoices',
          outgoinginvoices: 'Outgoing Invoices',
          accounting: 'Accounting',
          invoice: 'Invoice',
          invoices: 'Invoices',
          total: 'Total',
          print: 'Print',
          projectinfo: 'Project Info',
          chargedhours: 'Chargable Hours',
          contactperson: 'Ansprechpartner',
          profitchart: 'Profit Chart',
          clearing: 'Clearing',
          nodatafound: 'No Data',
          timer: 'Timer',
          manual: 'Manual',
          charged: 'Charged',
          notcharged: 'Not Charged',
          lead: 'Lead',
          leads: 'Leads',
          fails: 'Fails',
          prospects: 'Prospects',
          sales: 'Sales',
          vat: 'VAT',
          bank: 'Bank',
          iban: 'IBAN',
          bic: 'BIC',
          agency: 'Agency',
          directcustomer: 'Direct Customer',
          headhunter: 'Headhunter',
          freelancer: 'Freelancer',
          temporaryemploymentagency: 'Temporary Employment Agency',
          projectbroker: 'Project Broker',
          inquiry: 'Inquiry',
          application: 'Application',
          comment: 'Comment',
          customertype: 'Customer Type',
          channel: 'Channel',
          resubmit: 'Resubmission',
          hourly: 'Rate per Hour',
          scheduler: 'Scheduler',
          today: 'Today',
          meeting: 'Meeting',
          searchresults: 'Searchresults',
          search: 'Search',
          quote: 'Quote',
          quotes: 'Quotes',
          gender_m: 'Mr.',
          gender_f: 'Mrs.',
          openInvoices: 'Open Invoices',
          invoiceamount: 'Invoice Amount',
          actualhours: 'Actual hours',
          paymentinformations: 'Payment informations',
          due: 'Due',
          acquisitions: 'Acquisitions',
          acquisition: 'Acquisition',
          saleslevel: 'Saleslevel',
          billingmode: 'Billing Mode',
          topcustomers: 'Top Kunden',
          cancelled: 'Cancelled',
          invoiced: 'Invoiced',
          comdate: 'Contact Date',
          communication: 'Communication',
          conversations: 'Conversations',
          projecttitle: 'Project',
          offerdate: 'Offerdate',
          quotepositions: 'Quote positions',
          quoteposition: 'Quote position',
          category: 'Category',
          categories: 'Categories',
          min: 'from',
          max: 'to',
          none: 'none',
          salesmonth: 'Salesmonth',
          companycolor: 'Companycolor',
          correspondences: 'Correspondenses',
          correspondence: 'Correspondense',
          email: 'E-Mail',
          letter: 'Letter',
          editing: 'Editing',
          submitted: 'Submitted',
          assumed: 'Assumed',
          rejected: 'Rejected',
          canceled: 'Canceled',
          offer: 'Offer',
          costestimation: 'Cost estimation',
          quotenumber: 'Quote number',
          showsum: 'Show total',
          nosum: 'No total',
     },
     de: {
          starth1: 'Willkommen bei freelanceStudio, die neue Verwaltungssoftware für Freelancer',
          starth2: 'Jetzt kostenlos registrieren und ganz einfach <b>Kunden</b>, <b>Projekte</b>, <b>Aufgaben</b> und <b>Arbeitszeiten</b> organisieren',
          save: 'Speichern',
          saving: 'Wird gespeichert...',
          saved: 'Gespeichert',
          error: 'Fehler',
          settings: 'Einstellungen',
          currency: 'Währung',
          language: 'Sprache',
          profile: 'Profil',
          logout: 'Ausloggen',
          dashboard: 'Übersicht',
          period: 'Zeitraum',
          date: 'Datum',
          day: 'Tag',
          week: 'Woche',
          month: 'Monat',
          year: 'Jahr',
          hour: 'Stunde',
          hours: 'Stunden',
          minute: 'Minute',
          minutes: 'Minuten',
          second: 'Sekunde',
          seconds: 'Sekunden',
          effort: 'nach Aufwand',
          request: 'auf Anfrage',
          free: 'kostenlos',
          mon: 'Mo',
          tue: 'Di',
          wed: 'Mi',
          thu: 'Do',
          fri: 'Fr',
          sat: 'Sa',
          sun: 'So',
          monday: 'Montag',
          tuesday: 'Dienstag',
          wednesday: 'Mittwoch',
          thursday: 'Donnerstag',
          friday: 'Freitag',
          saturday: 'Samstag',
          sunday: 'Sonntag',
          profit: 'Erfolg',
          wh: 'Arbeitszeiten',
          taskboard: 'Aufgabenliste',
          todo: 'Noch zu erledigen',
          inprogress: 'In Arbeit',
          inreview: 'In Review',
          done: 'Erledigt',
          project: 'Projekt',
          projects: 'Projekte',
          cards: 'Karten',
          list: 'Liste',
          projectsfound: 'Projekt(e) gefunden',
          quotesfound: 'Angebot(e) gefunden',
          searchprojects: 'Projekte durchsuchen...',
          searchquotes: 'Angebote durchsuchen...',
          bookingsfound: 'Buchung(en) gefunden',
          searchbookings: 'Buchungen durchsuchen...',
          company: 'Firma',
          companies: 'Unternehmen',
          searchcompanies: 'Unternehmen durchsuchen...',
          companiesfound: 'Unternehmen gefunden',
          contact: 'Kontakt',
          contacts: 'Kontakte',
          searchcontacts: 'Kontakte durchsuchen...',
          contactsfound: 'Kontakt(e) gefunden',
          close: 'Schliessen',
          details: 'Details',
          street: 'Straße',
          city: 'Ort',
          postalcode: 'Postleitzahl',
          country: 'Land',
          mr: 'Herr',
          mrs: 'Frau',
          firstname: 'Vorname',
          lastname: 'Zuname',
          position: 'Position',
          phone: 'Festnetz',
          mobile: 'Mobil',
          distance: 'Entfernung',
          drivingtime: 'Fahrtzeit',
          orderdate: 'Auftragsdatum',
          duedate: 'Fällig',
          title: 'Titel',
          customer: 'Auftraggeber',
          subcustomer: 'Kunde',
          customers: 'Kunden',
          rate: 'Stundensatz',
          task: 'Aufgabe',
          tasks: 'Aufgaben',
          taskdescription: 'Aufgabenbeschreibung...',
          status: 'Status',
          description: 'Beschreibung',
          describeproject: 'Projektbeschreibung...',
          type: 'Typ',
          start: 'Beginn',
          end: 'Ende',
          time: 'Zeit',
          times: 'Zeiten',
          starttime: 'Anfangszeit',
          charge: 'Berechnet',
          nocharge: 'Ohne Berechnung',
          homeoffice: 'Home-Office',
          onsitecust: 'Büro Auftraggeber',
          onsitesubcust: 'Büro Kunde',
          step: 'Schritt',
          cust: 'Kunde',
          tracktime: 'Zeiterfassung',
          analyse: 'Auswertung',
          person: 'Person',
          accessdata: 'Zugangsdaten',
          password: 'Passwort',
          password2: 'Passwort 2',
          passwordr: 'Passwort wiederholen',
          introduction: 'Einführung',
          introductiontext: 'Einleitungstext',
          introtext:
               'Servus {firstname}! Hier siehst Du, wie Du in 5 Schritten mit freelanceStudio effektiv arbeiten kannst:',
          addcompany: 'Unternehmen hinzufügen',
          addproject: 'Projekt hinzufügen',
          addquote: 'Angebot hinzufügen',
          addcontact: 'Kontakt hinzufügen',
          addbooking: 'Buchung hinzufügen',
          addtask: 'Aufgabe hinzufügen',
          addlead: 'Lead hinzufügen',
          taxrate: 'Mehrwertsteuer',
          shr: 'Standard Stundensatz',
          perhour: '/h',
          invoucedatenumber: 'Rechnungsdatum / -nummer',
          companyproject: 'Firma / Projekt',
          companyprojectinfo: 'Firma / Projekt / Info',
          info: 'Info',
          payinfo: 'Zahlinfo',
          booking: 'Buchung',
          amounts: 'Beträge',
          amount: 'Betrag',
          income: 'Einnahmen',
          outgoing: 'Ausgaben',
          gross: 'brutto',
          tax: 'Steuer',
          paymethod: 'Zahlungsmethode',
          invoicedate: 'Rechnungsdatum',
          invoiceno: 'Rechnungsnummer',
          bookaccount: 'Buchungskonto',
          bookingmonth: 'Buchungsmonat',
          netamount: 'Nettobetrag',
          paydate: 'Zahlungsdatum',
          bookingtype: 'Buchungstyp',
          overview: 'Übersicht',
          bookings: 'Buchungen',
          incominginvoices: 'Eingangsrechnungen',
          outgoinginvoices: 'Ausgangsrechnungen',
          accounting: 'Buchhaltung',
          invoice: 'Rechnung',
          invoices: 'Rechnungen',
          total: 'Summe',
          print: 'Druck',
          projectinfo: 'Informationen zum Projekt',
          chargedhours: 'Verrechenbare Stunden',
          contactperson: 'Ansprechpartner',
          profitchart: 'Erfolgsstatistik',
          clearing: 'Verrechnung',
          nodatafound: 'Keine Daten vorhanden',
          timer: 'Stopuhr',
          manual: 'Manuell',
          charged: 'Berechnet',
          notcharged: 'Ohne Berechnung',
          lead: 'Lead',
          leads: 'Leads',
          fails: 'Fails',
          prospects: 'Prospects',
          sales: 'Vertrieb',
          vat: 'USt-ID',
          bank: 'Bank',
          iban: 'IBAN',
          bic: 'BIC',
          agency: 'Agentur',
          directcustomer: 'Direktkunde',
          headhunter: 'Headhunter',
          freelancer: 'Freelancer',
          temporaryemploymentagency: 'Zeitarbeitsfirma',
          projectbroker: 'Projektvermittler',
          inquiry: 'Anfrage',
          application: 'Bewerbung',
          comment: 'Bemerkung',
          customertype: 'Kundentyp',
          channel: 'Kanal',
          resubmit: 'Wiedervorlage',
          hourly: 'Stundensatz',
          scheduler: 'Terminkalender',
          today: 'Heute',
          meeting: 'Termin',
          searchresults: 'Suchergebnisse',
          search: 'Suche',
          quote: 'Angebot',
          quotes: 'Angebote',
          gender_m: 'Herr',
          gender_f: 'Frau',
          openInvoices: 'Offene Rechnungen',
          invoiceamount: 'Rechnungsbetrag',
          actualhours: 'Tatsächliche Stunden',
          paymentinformations: 'Zahlungsinformationen',
          due: 'Fällig',
          acquisitions: 'Akquisen',
          acquisition: 'Akquise',
          saleslevel: 'Verkaufsstufe',
          billingmode: 'Abrechnungsmodus',
          topcustomers: 'Top Kunden',
          cancelled: 'Storniert',
          invoiced: 'Fakturiert',
          comdate: 'Kontaktdatum',
          communication: 'Kommunikation',
          conversations: 'Konversationen',
          projecttitle: 'Projekt',
          offerdate: 'Angebotsdatum',
          quotepositions: 'Angebotspositionen',
          quoteposition: 'Angebotsposition',
          category: 'Kategorie',
          categories: 'Kategories',
          min: 'Von',
          max: 'Bis',
          none: 'Nicht vorhanden',
          salesmonth: 'Vertriebsmonat',
          companycolor: 'Firmenfarbe',
          correspondences: 'Korrespondenzen',
          correspondence: 'Korrespondenz',
          email: 'E-Mail',
          letter: 'Brief',
          editing: 'In Bearbeitung',
          submitted: 'Verschickt',
          assumed: 'Angenommen',
          rejected: 'Abgelehnt',
          canceled: 'Gelöscht',
          offer: 'Angebot',
          costestimation: 'Aufwandsschätzung',
          quotenumber: 'Angebotsnummer',
          showsum: 'Summe anzeigen',
          nosum: 'Ohne Summe',
     },
}

const i18n = new VueI18n({
     locale: 'en', // set locale
     fallbackLocale: 'en',
     messages, // set locale messages
})

store.dispatch('users/check_locale').then((res) => {
     let language = res.substring(0, 2)
     i18n.locale = language
})

export default i18n
