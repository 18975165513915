<template>
     <div id="app">
          <div class="header sticky-top">
               <div class="row">
                    <div class="col-4">
                         <img :src="fls_Logo" alt="freelanceStudio" />
                    </div>
                    <div class="col-8">
                         <div class="row">
                              <div class="col-3">
                                   <stopwatch v-if="showTools" />
                              </div>

                              <div class="col-4 text-right pt-5">
                                   <font-awesome-icon
                                        icon="search"
                                        style="font-size: 1.2rem"
                                        v-if="searchIcon"
                                        @click="toggleSearchBar"
                                        class="mt-1"
                                   />
                                   <input
                                        v-else
                                        class="form-control"
                                        type="search"
                                        v-model="searchterm"
                                        :placeholder="$t('search')"
                                        aria-label="Search"
                                        @focusout="toggleSearchBar"
                                        @keyup.enter="
                                             $router.push({
                                                  path: '/searchresults',
                                             })
                                        "
                                   />
                              </div>
                              <div class="col-3 pt-5">
                                   <div class="clockbox">
                                        {{ currentDate }}
                                   </div>
                              </div>
                              <div class="col-2">
                                   <statusbar
                                        style="
                                             margin-right: 0;
                                             margin-left: auto;
                                        "
                                        v-if="showTools"
                                   />
                              </div>
                         </div>
                    </div>
               </div>
          </div>
          <div id="nav" :class="menustatus ? 'active' : ''" v-show="showTools">
               <div class="navicon">
                    <font-awesome-icon
                         icon="bars"
                         v-show="!menustatus"
                         @click="openMenu"
                    />
               </div>
               <div class="navcontent">
                    <router-link
                         v-for="(nav, index) in navigation"
                         :key="index"
                         :to="nav.available ? nav.link : '?'"
                         :title="nav.title"
                         :class="nav.available ? '' : 'disabled'"
                         ><font-awesome-icon :icon="nav.icon"
                    /></router-link>
                    <span>
                         <font-awesome-icon
                              icon="times-circle"
                              @click="closeMenu"
                              :title="$t('close')"
                         />
                    </span>
               </div>
          </div>
          <div v-if="screenloader">
               Lädt
               <b-spinner small label="Small Spinner" type="grow"></b-spinner>
          </div>
          <div v-else>
               <transition name="toggleMain">
                    <router-view class="main" />
               </transition>
          </div>

          <div id="addBtnMenu" v-show="showTools">
               <addnav
                    :btnVals="addbuttons"
                    :options="addBtnOptions"
                    @menupoint="newEntry"
               />
          </div>

          <messagebox />

          <quickaddform />
     </div>
</template>

<script>
import addnav from '@/components/AddNavigation.vue'
import quickaddform from '@/components/QuickAddForm.vue'
import messagebox from '@/components/Messagebox.vue'
import statusbar from '@/components/StatusBar.vue'
import stopwatch from '@/components/StopWatch.vue'
//import { currency, datetime } from '@/mixins/helpers.js'
import moment from 'moment'

export default {
     name: 'app',
     components: {
          addnav,
          quickaddform,
          messagebox,
          statusbar,
          stopwatch,
     },
     data() {
          return {
               menustatus: false,
               addbuttons: [
                    {
                         ID: 1,
                         title: this.$t('company'),
                         value: 'company',
                         icon: 'building',
                         status: '',
                    },
                    {
                         ID: 4,
                         title: this.$t('contact'),
                         value: 'contact',
                         icon: 'user',
                         status: '',
                    },
                    {
                         ID: 5,
                         title: this.$t('booking'),
                         value: 'booking',
                         icon: 'balance-scale',
                         status: '',
                    },
                    {
                         ID: 2,
                         title: this.$t('quote'),
                         value: 'quote',
                         icon: 'money-check',
                         status: '',
                    },
                    {
                         ID: 3,
                         title: this.$t('project'),
                         value: 'project',
                         icon: 'project-diagram',
                         status: '',
                    },
                    {
                         ID: 4,
                         title: this.$t('task'),
                         value: 'task',
                         icon: 'tasks',
                         status: '',
                    },
                    {
                         ID: 5,
                         title: this.$t('meeting'),
                         value: 'event',
                         icon: 'business-time',
                         status: '',
                    },
                    {
                         ID: 6,
                         title: this.$t('lead'),
                         value: 'lead',
                         icon: 'funnel-dollar',
                         status: '',
                    },
               ],
               addBtnOptions: { arcSize: 120, distance: 160, startAngle: 260 },
          }
     },
     computed: {
          currentDate() {
               return moment().format('DD.MM.YYYY')
          },
          screenloader() {
               return this.$store.state.usability.screenloader
          },
          fls_Logo() {
               return require('@/assets/images/freelanceStudio_Logo.png')
          },
          activeTasks() {
               return this.$store.getters['projects/activeTaskList']
          },
          progressStatus() {
               return this.$store.getters['users/getProgressStatus']
          },
          contacts() {
               return this.$store.getters['companies/contactList']
          },
          navigation() {
               let contAvail = false
               if (this.contacts.length > 0) {
                    contAvail = true
               }
               return [
                    {
                         title: this.$t('dashboard'),
                         icon: 'tachometer-alt',
                         link: '/dashboard',
                         available: true,
                    },
                    {
                         title: this.$t('scheduler'),
                         icon: 'calendar-day',
                         link: '/scheduler',
                         available: true,
                    },
                    {
                         title: this.$t('taskboard'),
                         icon: 'tasks',
                         link: '/taskboard',
                         available: true,
                    },
                    {
                         title: this.$t('projects'),
                         icon: 'project-diagram',
                         link: '/projects',
                         available: true,
                    },
                    {
                         title: this.$t('quotes'),
                         icon: 'money-check',
                         link: '/quotes',
                         available: true,
                    },
                    {
                         title: this.$t('sales'),
                         icon: 'funnel-dollar',
                         link: '/sales',
                         available: true,
                    },
                    {
                         title: this.$t('bookings'),
                         icon: 'balance-scale',
                         link: '/bookings',
                         available: true,
                    },
                    {
                         title: this.$t('companies'),
                         icon: 'building',
                         link: '/companies',
                         available: true,
                    },
                    {
                         title: this.$t('contacts'),
                         icon: 'users',
                         link: '/contacts',
                         available: contAvail,
                    },
                    {
                         title: this.$t('correspondences'),
                         icon: 'feather',
                         link: '/correspondences',
                         available: contAvail,
                    },
                    /* {title: 'Trash', icon: 'recycle', link: '/trash'}, */
               ]
          },
          userStatus() {
               return this.$store.state.users.userstatus
          },
          showTools() {
               if (this.userStatus && this.$route.name != 'Start') return true
               else return false
          },
          searchIcon() {
               return this.$store.state.administration.searchIcon
          },
     },
     watch: {
          progressStatus() {
               this.feedAddButtons()
          },
     },
     methods: {
          toggleSearchBar() {
               if (this.searchIcon) {
                    this.$store.state.administration.searchIcon = false
               } else {
                    this.$store.state.administration.searchIcon = true
               }
          },
          openMenu() {
               this.menustatus = true
          },
          closeMenu() {
               this.menustatus = false
          },
          newEntry(type) {
               let status = this.progressStatus.value
               let open = false
               switch (type) {
                    case 'company':
                         open = true
                         break
                    case 'booking':
                         open = true
                         break
                    case 'project':
                         if (status >= 2) {
                              open = true
                         }
                         break
                    case 'quote':
                         if (status >= 2) {
                              open = true
                         }
                         break
                    case 'contact':
                         if (status >= 2) {
                              open = true
                         }
                         break
                    case 'task':
                         if (status >= 3) {
                              open = true
                         }
                         break
                    case 'lead':
                         if (status >= 2) {
                              open = true
                         }
                         break
                    case 'event':
                         if (status >= 3) {
                              open = true
                         }
                         break
               }
               if (open == true) {
                    this.$store.commit('users/SET_ADDFORM', type)
                    this.$bvModal.show('quickAddModal')
               } else {
                    this.$store.commit('messages/ADD_MESSAGE', {
                         text:
                              'Not allowed yet! Please add a ' +
                              this.progressStatus.name +
                              ' first!',
                         icon: 'info',
                         color: 'red',
                         time: 5000,
                    })
               }
          },
          feedAddButtons() {
               let Btns = []
               this.addbuttons.forEach((b) => {
                    let status = ''
                    if (b.ID == this.progressStatus.value) {
                         status = 'puls'
                    } else if (b.ID > this.progressStatus.value) {
                         status = 'blocked'
                    }
                    Btns.push({
                         ID: b.ID,
                         value: b.value,
                         title: b.title,
                         icon: b.icon,
                         status: status,
                    })
               })
               this.addbuttons = Btns
          },
     },
}
</script>

<style lang="sass">
@import '@/assets/SASS/main.sass'
#app
    margin: 0
    padding: 0
    color: $darkgrey
    font-size: 14px
    font-family: Mainfont, sans-serif, Arial, Helvetica
    position: relative
    .nav-link
        text-transform: uppercase
        &.active
            color: $darkgrey
            font-weight: bold
    .header
        background-color: white
        z-index: 500
        img
            width: 100%
            max-width: 200px
            height: auto
            margin: 2vh 5%
    #nav
        display: block
        position: fixed
        top: 13vh
        left: 0
        @media #{$smartphone}
            top: 16vh
            left: auto
            right: 5%
        background-color: $backgrey
        color: #fff
        font-size: 1.2rem
        text-align: center
        width: 50px
        height: 50px
        border-radius: 50%
        transition: 1s
        z-index: 600
        margin-left: 2%
        opacity: 1
        &.active
            width: auto
            min-width: 55px
            height: 75vh
            min-height: 500px
            border-radius: 0 150px 120px 0
            margin-left: 0
            opacity: 0.9
            transition: 1s
            @media #{$smartphone}
                border-radius: 150px 0 0 120px
                margin-right: 0
                right: 0
            .navcontent
                display: block
                opacity: 1
                transition: 1s cubic-bezier(0.36, 1.15, 0, 0.89)
                width: 55px
        .navicon
            position: absolute
            top: 50%
            left: 50%
            margin: auto
            -webkit-transform: translate(-50%, -50%)
            transform: translate(-50%, -50%)
        .navcontent
            margin: 0
            font-size: 1.2rem
            display: none
            transition: 1s
            width: 0
            height: 0
            background-color: white
            opacity: 0
            text-align: center
            line-height: 3rem
            color: #fff
            text-align: center
            width: 100%
            a
                text-decoration: none
                color: #fff
                width: 100%
                text-align: center
                display: block
                &:first-of-type
                    padding-top: 40px
                &:hover
                    color: $primarycolor
                &.router-link-active
                    color: $primarycolor
                &.disabled
                    color: lighten($darkgrey, 2%)
    .main
        padding-left: 5%
        margin-top: 5vh
    #addBtnMenu
        position: fixed
        bottom: 10vh
        right: 7vh
    .clockbox
        position: relative
        text-align: center
        // background-color: $darkgrey
        // color: white
        letter-spacing: 0.02rem
        padding: 2px 10px
        border-radius: 4px
        font-size: 0.8rem
        width: 6rem
        margin-right: 0
        margin-left: auto
        font-weight: bold
        border: 1px dotted $lightgrey
</style>
