<template>
     <div>
          <div id="inputfield">
               <label :class="labelStatus ? 'active' : ''">{{ label }}</label>
               <input
                    class="inputfield"
                    v-model="inputVal"
                    :type="type"
                    :min="min"
                    :max="max"
                    @focus="focusLabel"
                    @blur="blurLabel"
                    :style="type == 'number' ? 'text-align: center' : ''"
                    :disabled="disabled"
               />
               <div v-show="append" class="append">{{ append }}</div>
          </div>
     </div>
</template>

<script>
export default {
     name: 'input-field',
     props: {
          label: String,
          value: String,
          type: {
               type: String,
               default: 'text',
          },
          append: {
               type: String,
               default: '',
          },
          min: {
               type: String,
          },
          max: {
               type: String,
          },
          disabled: {
               type: Boolean,
               default: false,
          },
     },
     data: function () {
          return {
               labelStatus: false,
          }
     },
     computed: {
          inputVal: {
               get() {
                    return this.value
               },
               set(val) {
                    this.$emit('input', val)
               },
          },
     },
     methods: {
          focusLabel() {
               this.labelStatus = true
          },
          blurLabel() {
               this.checkInput()
          },
          checkInput() {
               if (this.value) {
                    this.labelStatus = true
               } else {
                    this.labelStatus = false
               }
          },
     },
     created() {
          this.checkInput()
     },
}
</script>

<style lang="sass" scoped>
@import '@/assets/SASS/main.sass'
#inputfield
    display: inline-block
    position: relative
    background-color: white
    height: 2.8rem
    width: 100%
    label
        position: absolute
        top: 0.45rem
        left: 1rem
        font-size: 0.9rem
        transition: 0.6s
        color: #AAA
        letter-spacing: 0.05rem
        &.active
            top: -0.5rem
            font-size: 0.7rem
            z-index: 10
            transition: 0.6s
            background-color: white
            padding: 0 5px
            width: auto
            color: #8dd7c1
    .inputfield
        position: absolute
        top: 0
        left: 0
        background-color: rgba(255,255,255,0)
        border-top: 1px solid #DDD
        border-right: 1px solid #DDD
        border-left: 1px solid #DDD
        border-bottom: 1px solid #CCC
        padding: 0.4rem 1rem
        border-radius: 6px
        width: 100%
        font-size: 1rem
        &:focus
            outline-color: #8dd7c1
    .append
        position: absolute
        bottom: -2px
        right: 5px
        background-color: lighten($primarycolor, 15%)
        color: white
        padding: 0 8px
        border-radius: 4px
</style>
