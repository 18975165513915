import axios from 'axios'

const apiClient = axios.create({
     baseURL: `https://freelancestudio.de/backend/`,
     withCredentials: false, // This is the default
     headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
     },
     timeout: 10000,
})

const queryString = function (data) {
     let query = 'queries.php?'
     let i = 0
     let connector = ''
     let userData = localStorage.getItem('user')
     userData = JSON.parse(userData)
     if (userData) {
          data.userID = userData.userID
     }
     for (const [key, value] of Object.entries(data)) {
          if (i != 0) {
               connector = '&'
          } else {
               connector = ''
          }
          query = query + connector + key + '=' + value
          i++
     }
     return query
}

export default {
     runQuery(data) {
          console.log(queryString(data))
          return apiClient.get(queryString(data))
     },
}
