import dbService from '@/services/dbService.js'
import basicFunctions from '@/services/basicFunctions.js'

export const namespaced = true

const sortByField = function (list, fieldName, ascending = true) {
     let l = list.sort((a, b) => {
          const fieldA = a[fieldName]
          const fieldB = b[fieldName]

          if (ascending) {
               return fieldA < fieldB ? -1 : fieldA > fieldB ? 1 : 0
          } else {
               return fieldB < fieldA ? -1 : fieldB > fieldA ? 1 : 0
          }
     })
     return l
}

export const state = {
     quotesdata: [],
     positionsdata: [],
     quotestatus: ['Editing', 'Submitted', 'Assumed', 'Rejected', 'Canceled'],
     quotepositioncategories: ['Basis', 'Optional'],
     quotepositiontypes: ['hours', 'pauschal', 'effort', 'request', 'free'],
}

export const mutations = {
     FILL_QUOTESDATA(state, quotes) {
          state.quotesdata = quotes
     },
     FILL_POSITIONSDATA(state, positions) {
          state.positionsdata = positions
     },
     CLEAR_QUOTES(state) {
          state.quotesdata = []
          state.quotes = []
     },
     CLEAR_POSITIONS(state) {
          state.positionsdata = []
     },
}

export const actions = {
     get_Quotes({ commit }, data) {
          data.query = 'getQuotes'
          return dbService
               .runQuery(data)
               .then((response) => {
                    if (response.status == 200) {
                         commit('FILL_QUOTESDATA', response.data)
                    }
               })
               .catch((error) => {
                    console.log(error)
               })
     },
     get_Positions({ commit }, data) {
          data.query = 'getPositions'
          return dbService
               .runQuery(data)
               .then((response) => {
                    if (response.status == 200) {
                         commit('FILL_POSITIONSDATA', response.data)
                    }
               })
               .catch((error) => {
                    console.log(error)
               })
     },
     add_Quote({ dispatch, commit }, data) {
          data.ID = basicFunctions.generate_token(10)
          data.query = 'addQuote'
          return dbService
               .runQuery(data)
               .then((response) => {
                    if (response.status != 200) {
                         commit(
                              'messages/ADD_MESSAGE',
                              {
                                   text: 'Sorry! Something went wront, so we could not save the quote. Please try it again.',
                                   icon: 'info',
                                   color: 'red',
                                   time: 5000,
                              },
                              { root: true }
                         )
                    } else {
                         dispatch('get_Quotes', {})
                    }
               })
               .catch((error) => {
                    console.log(error)
               })
     },
     update_Quote({ dispatch, commit }, data) {
          data.query = 'updateQuote'
          data.description = encodeURIComponent(data.description)
          data.introduction = encodeURIComponent(data.introduction)
          data.projecttitle = encodeURIComponent(data.projecttitle)
          return dbService
               .runQuery(data)
               .then((response) => {
                    if (response.status != 200) {
                         commit(
                              'messages/ADD_MESSAGE',
                              {
                                   text: 'Sorry! Something went wront, so we could not save the quote. Please try it again.',
                                   icon: 'info',
                                   color: 'red',
                                   time: 5000,
                              },
                              { root: true }
                         )
                         return 'FAILED'
                    } else {
                         dispatch('get_Quotes', {})
                         dispatch('get_QuotePositions', {})
                         commit(
                              'messages/ADD_MESSAGE',
                              {
                                   text: 'Quote saved sucessful!',
                                   icon: 'check',
                                   color: 'green',
                                   time: 5000,
                              },
                              { root: true }
                         )
                         return 'OK'
                    }
               })
               .catch((error) => {
                    console.log(error)
               })
     },
     update_QuotePosition({ dispatch, commit }, data) {
          data.query = 'updateQuotePosition'
          data.description = encodeURIComponent(data.description)
          return dbService
               .runQuery(data)
               .then((response) => {
                    if (response.status != 200) {
                         commit(
                              'messages/ADD_MESSAGE',
                              {
                                   text: 'Sorry! Something went wront, so we could not save the quote. Please try it again.',
                                   icon: 'info',
                                   color: 'red',
                                   time: 5000,
                              },
                              { root: true }
                         )
                         return 'FAILED'
                    } else {
                         dispatch('get_Quotes', {})
                         dispatch('get_QuotePositions', {})
                         commit(
                              'messages/ADD_MESSAGE',
                              {
                                   text: 'Quoteposition saved sucessful!',
                                   icon: 'check',
                                   color: 'green',
                                   time: 5000,
                              },
                              { root: true }
                         )
                         return 'OK'
                    }
               })
               .catch((error) => {
                    console.log(error)
               })
     },
     update_QuotePositionOrder(base, data) {
          data.query = 'updateQuotePositionOrder'
          return dbService
               .runQuery(data)
               .then((response) => {
                    if (response.status != 200) {
                         return 'FAILED'
                    } else {
                         return 'OK'
                    }
               })
               .catch((error) => {
                    console.log(error)
               })
     },
     add_QuotePosition({ dispatch, commit }, data) {
          data.query = 'addQuotePosition'
          data.ID = basicFunctions.generate_token(10)
          return dbService
               .runQuery(data)
               .then((response) => {
                    if (response.status != 200) {
                         commit(
                              'messages/ADD_MESSAGE',
                              {
                                   text: 'Sorry! The position could not be saved. Please try it again!',
                                   icon: 'info',
                                   color: 'red',
                                   time: 5000,
                              },
                              { root: true }
                         )
                         return 'FAIL'
                    } else {
                         commit(
                              'messages/ADD_MESSAGE',
                              {
                                   text: 'New position saved sucessful!',
                                   icon: 'check',
                                   color: 'green',
                                   time: 5000,
                              },
                              { root: true }
                         )
                         dispatch('get_Quotes', {})
                         dispatch('get_QuotePositions', {})
                         return data
                    }
               })
               .catch((error) => {
                    console.log(error)
               })
     },
     update_Position({ commit, dispatch }, data) {
          data.query = 'updatePosition'
          return dbService
               .runQuery(data)
               .then((response) => {
                    if (response.status != 200) {
                         commit(
                              'messages/ADD_MESSAGE',
                              {
                                   text:
                                        'Sorry! ' +
                                        data.title +
                                        ' could not be saved. Please try it again!',
                                   icon: 'info',
                                   color: 'red',
                                   time: 5000,
                              },
                              { root: true }
                         )
                         return 'FAILED'
                    } else {
                         dispatch('get_Quotes', {})
                         dispatch('get_QuotePositions', {})
                         commit(
                              'messages/ADD_MESSAGE',
                              {
                                   text: data.title + ' saved sucessful!',
                                   icon: 'check',
                                   color: 'green',
                                   time: 5000,
                              },
                              { root: true }
                         )
                         return 'OK'
                    }
               })
               .catch((error) => {
                    console.log(error)
               })
     },
}

export const getters = {
     foundQuotes: (state) => (searchterm) => {
          let quotes = []
          if (state.quotesdata) {
               let fields = [
                    'projecttitle',
                    'customer',
                    'subcustomer',
                    'status',
               ]
               searchterm = searchterm.toLowerCase().replace(' ', '')
               quotes = state.quotesdata.filter((q) => {
                    let found = false
                    fields.forEach((f) => {
                         found =
                              found ||
                              q[f]
                                   .toLowerCase()
                                   .replace(' ', '')
                                   .includes(searchterm)
                    })
                    return found
               })
          }
          return quotes
     },
     quoteList: (state) => {
          let quotelist = []
          if (state.quotesdata) {
               state.quotesdata.forEach((q) => {
                    quotelist.push({ value: q.ID, text: q.projecttitle })
               })
          }
          return quotelist
     },
     quote: (state) => (ID) => {
          let quote = []
          if (state.quotesdata) {
               quote = state.quotesdata.find((q) => q.ID == ID)
          }
          return quote
     },
     position: (state) => (ID) => {
          let position = []
          if (state.positionsdata) {
               position = state.positionsdata.find((p) => p.ID == ID)
          }
          return position
     },
     quotepositions: (state) => (ID) => {
          let positions = []
          if (state.positionsdata) {
               positions = state.positionsdata.filter((p) => p.quoteID == ID)
               positions = sortByField(positions, 'position')
          }
          return sortByField(positions, 'position')
     },
     companyquotes: (state) => (ID) => {
          let quotes = []
          if (state.quotesdata) {
               quotes = state.quotesdata.filter(
                    (q) => q.customerid == ID || q.subcustomerid == ID
               )
          }
          return quotes
     },
     quotes: (state) => {
          let quotes = []
          if (state.quotesdata) {
               quotes = state.quotesdata
          }
          return quotes
     },
}
