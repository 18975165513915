export default {
     generate_token(length) {
          var a =
               'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890'.split(
                    ''
               )
          var b = []
          for (var i = 0; i < length; i++) {
               var j = (Math.random() * (a.length - 1)).toFixed(0)
               b[i] = a[j]
          }
          return b.join('')
     },
     sortObject(object, field) {
          return object.sort((a, b) => (a[field] > b[field] ? 1 : -1))
     },
}
