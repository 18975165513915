var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"v_menu"}},[_c('div',{staticClass:"v_Btn",class:_vm.toggleStatus ? 'active' : '',attrs:{"id":"v_addBtn"},on:{"click":_vm.toggleMenu}},[_c('font-awesome-icon',{attrs:{"icon":"plus"}})],1),_vm._l((_vm.buttons),function(btn,index){return _c('div',{key:index,staticClass:"v_navEl v_Btn",class:[_vm.menuClass, btn.status],style:(_vm.toggleStatus
                    ? 'transform: translate(' +
                      btn.xPos +
                      'px , ' +
                      btn.yPos +
                      'px )'
                    : ''),attrs:{"title":btn.title},on:{"click":function($event){return _vm.selectMenuPoint(btn.value)}}},[_c('font-awesome-icon',{attrs:{"icon":btn.icon}})],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }