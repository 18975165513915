import Vue from 'vue'
import VueRouter from 'vue-router'
//import store from '@/store'

Vue.use(VueRouter)

const routes = [
     {
          path: '/',
          name: 'Start',
          component: () => import('../views/Start.vue'),
     },
     {
          path: '/start',
          name: 'StartPoint',
          component: () => import('../views/Start.vue'),
     },
     {
          path: '/dashboard',
          name: 'Dashboard',
          component: () => import('../views/Dashboard.vue'),
     },
     {
          path: '/taskboard',
          name: 'Tasks',
          component: () => import('../views/Tasks.vue'),
     },
     {
          path: '/projects',
          name: 'Projects',
          component: () => import('../views/Projects.vue'),
     },
     {
          path: '/bookings',
          name: 'Bookings',
          component: () => import('../views/Bookings.vue'),
     },
     {
          path: '/booking/:ID',
          name: 'Booking',
          component: () => import('../views/Booking.vue'),
     },
     {
          path: '/project/:ID',
          name: 'Project',
          component: () => import('../views/Project.vue'),
     },
     {
          path: '/quotes',
          name: 'Quotes',
          component: () => import('../views/Quotes.vue'),
     },
     {
          path: '/quote/:ID',
          name: 'Quote',
          component: () => import('../views/Quote.vue'),
     },
     {
          path: '/settings',
          name: 'Settings',
          component: () => import('../views/Settings.vue'),
     },
     {
          path: '/profil',
          name: 'Profil',
          component: () => import('../views/Profil.vue'),
     },
     {
          path: '/companies',
          name: 'Companies',
          component: () => import('../views/Companies.vue'),
     },
     {
          path: '/company/:ID',
          name: 'Company',
          component: () => import('../views/Company.vue'),
     },
     {
          path: '/contacts',
          name: 'Contacts',
          component: () => import('../views/Contacts.vue'),
     },
     {
          path: '/contact/:ID',
          name: 'Contact',
          component: () => import('../views/Contact.vue'),
     },
     {
          path: '/task/:ID',
          name: 'Task',
          component: () => import('../views/Task.vue'),
     },
     {
          path: '/time/:ID',
          name: 'Time',
          component: () => import('../views/Time.vue'),
     },
     {
          path: '/intro',
          name: 'Intro',
          component: () => import('../views/Introduction.vue'),
     },
     {
          path: '/sales',
          name: 'Sales',
          component: () => import('../views/Sales.vue'),
     },
     {
          path: '/sale/:ID',
          name: 'Sale',
          component: () => import('../views/Sale.vue'),
     },
     {
          path: '/verify/:ID',
          name: 'Verify',
          component: () => import('../views/Verify.vue'),
     },
     {
          path: '/scheduler',
          name: 'Scheduler',
          component: () => import('../views/Scheduler.vue'),
     },
     {
          path: '/correspondences',
          name: 'Correspondences',
          component: () => import('../views/Correspondences.vue'),
     },
     {
          path: '/correspondence/:ID',
          name: 'Correspondence',
          component: () => import('../views/Correspondence.vue'),
     },
     {
          path: '/searchresults',
          name: 'Searchresults',
          component: () => import('../views/SearchResults.vue'),
     },
]

const router = new VueRouter({
     mode: 'history',
     base: process.env.BASE_URL,
     routes,
})

router.beforeEach((to, from, next) => {
     // ROUTING EasyWay
     let userData = JSON.parse(localStorage.getItem('user'))
     //console.log(userData)
     if (userData == null && to.path != '/') {
          //console.log('start', to)
          setTimeout(() => {
               next({ path: '/' })
          }, 500)
     } else {
          //console.log('next')
          next()
     }

     // Check if User has data Menu
     // setTimeout(function () {
     //      if (!store.getters['users/getUserData']) {
     //           if (to.name == 'Start' || to.name == 'Verify') next()
     //           else next({ path: '/' })
     //      } else {
     //           let list = {}
     //           switch (to.name) {
     //                case 'Companies':
     //                     list = store.state.companies.companiesdata.length
     //                     if (list == 0) next({ path: '/intro' })
     //                     else next()
     //                     break
     //                case 'Projects':
     //                     if (store.state.projects.projectsdata.length == 0)
     //                          next({ path: '/intro' })
     //                     else next()
     //                     break
     //                case 'Tasks':
     //                     if (store.state.projects.tasksdata.length == 0)
     //                          next({ path: '/intro' })
     //                     else next()
     //                     break
     //                default:
     //                     next()
     //                     break
     //           }
     //      }
     // }, 600)

     // store.dispatch('users/check_Access').then((d) => {
     //      console.log(d)
     //      if (d != 'Logout') {
     //           next()
     //      } else {
     //           next('')
     //      }
     // })
})

export default router
