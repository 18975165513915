import dbService from '@/services/dbService.js'
import basicFunctions from '@/services/basicFunctions.js'
import moment from 'moment'

export const namespaced = true

export const state = {
     bookingsdata: [],
     bookingaccounts: [],
     invoicepositions: [],
     startbookingmonth: '2018-09',
}

export const mutations = {
     FILL_BOOKINGSDATA(state, data) {
          state.bookingsdata = data
     },
     FILL_BOOKINGACCOUNTS(state, data) {
          state.bookingaccounts = data
     },
     FILL_INVOICEPOSITIONS(state, data) {
          state.invoicepositions = data
     },
     CLEAR_BOOKINGS() {
          state.bookingsdata = {}
          state.bookingaccounts = {}
     },
}

export const actions = {
     get_Bookings({ commit }, data) {
          data.query = 'getBookings'
          return dbService
               .runQuery(data)
               .then((response) => {
                    if (
                         response.status == 200 &&
                         response.data != 'Not found'
                    ) {
                         response.data.forEach((b) => {
                              b.duedate = moment(b.invoicedate)
                                   .add(b.creditperiod, 'days')
                                   .format('YYYY-MM-DD')
                         })
                         commit('FILL_BOOKINGSDATA', response.data)
                    }
               })
               .catch((error) => {
                    console.log(error)
               })
     },
     get_BookingAccounts({ commit }, data) {
          data.query = 'getBookingAccounts'
          return dbService
               .runQuery(data)
               .then((response) => {
                    if (
                         response.status == 200 &&
                         response.data != 'Not found'
                    ) {
                         commit('FILL_BOOKINGACCOUNTS', response.data)
                    }
               })
               .catch((error) => {
                    console.log(error)
               })
     },
     get_InvoicePositions({ commit }, data) {
          data.query = 'getInvoicePositions'
          return dbService
               .runQuery(data)
               .then((response) => {
                    console.log(response.data)
                    if (
                         response.status == 200 &&
                         response.data != 'Not found'
                    ) {
                         commit('FILL_INVOICEPOSITIONS', response.data)
                    }
               })
               .catch((error) => {
                    console.log(error)
               })
     },
     add_Booking({ dispatch, commit }, data) {
          data.ID = basicFunctions.generate_token(10)
          data.query = 'addBooking'
          return dbService
               .runQuery(data)
               .then((response) => {
                    if (response.status != 200) {
                         commit(
                              'messages/ADD_MESSAGE',
                              {
                                   text: 'Sorry! Something went wront, so we could not save the booking. Please try it again.',
                                   icon: 'info',
                                   color: 'red',
                                   time: 5000,
                              },
                              { root: true }
                         )
                    } else {
                         dispatch('get_Bookings', {})
                    }
               })
               .catch((error) => {
                    console.log(error)
               })
     },
     copy_Booking({ dispatch, commit }, data) {
          data.ID = basicFunctions.generate_token(10)
          data.query = 'copyBooking'
          return dbService
               .runQuery(data)
               .then((response) => {
                    if (response.status != 200) {
                         commit(
                              'messages/ADD_MESSAGE',
                              {
                                   text: 'Sorry! Something went wront, so we could not copy the booking. Please try it again.',
                                   icon: 'info',
                                   color: 'red',
                                   time: 5000,
                              },
                              { root: true }
                         )
                    } else {
                         dispatch('get_Bookings', {})
                    }
               })
               .catch((error) => {
                    console.log(error)
               })
     },
     update_Booking({ dispatch, commit }, data) {
          data.query = 'updateBooking'

          const bad = function () {
               commit(
                    'messages/ADD_MESSAGE',
                    {
                         text: 'Sorry! Something went wront, so we could not save the booking. Please try it again.',
                         icon: 'info',
                         color: 'red',
                         time: 5000,
                    },
                    { root: true }
               )
               return 'FAILED'
          }

          const good = function () {
               dispatch('get_Bookings', {})
               dispatch('projects/get_Projects', {}, { root: true })
               commit(
                    'messages/ADD_MESSAGE',
                    {
                         text: 'Booking saved sucessful!',
                         icon: 'check',
                         color: 'green',
                         time: 5000,
                    },
                    { root: true }
               )
               return 'OK'
          }

          return dbService
               .runQuery(data)
               .then((response) => {
                    if (response.status != 200) {
                         console.log(response.data)
                         return bad()
                    } else {
                         return good()
                    }
               })
               .catch((error) => {
                    console.log(error)
               })
     },
}

export const getters = {
     booking: (state) => (ID) => {
          let booking = {}
          if (state.bookingsdata) {
               booking = state.bookingsdata.find((b) => b.ID == ID)
          }
          return booking
     },
     bookings: (state) => {
          let bookings = []
          if (state.bookingsdata) {
               bookings = state.bookingsdata
          }
          return bookings
     },
     invoicePositions: (state) => (bookingID) => {
          let pos = []
          if (state.invoicepositions) {
               pos = state.invoicepositions.filter(
                    (i) => i.invoiceID == bookingID
               )
          }
          return pos
     },
     foundBookings: (state) => (searchterm) => {
          let bookings = []
          if (searchterm) {
               if (state.bookingsdata) {
                    let fields = [
                         'description',
                         'netamount',
                         'paymethod',
                         'bookingmonth',
                         'bookaccount',
                         'company',
                         'invoiceno',
                         'type',
                    ]
                    searchterm = searchterm.toLowerCase().replace(' ', '')
                    bookings = state.bookingsdata.filter((b) => {
                         let found = false
                         fields.forEach((f) => {
                              found =
                                   found ||
                                   b[f]
                                        .toLowerCase()
                                        .replace(' ', '')
                                        .includes(searchterm)
                         })
                         return found
                    })
               }
          } else {
               bookings = state.bookingsdata
          }
          return bookings
     },
     bookingAccount: (state) => (ID) => {
          let account = {}
          if (state.bookingaccounts) {
               account = state.bookingaccounts.filter((ba) => ba.ID == ID)
          }
          return account[0]
     },
     bookingAccountList: (state) => (type) => {
          let baList = []
          if (state.bookingaccounts) {
               state.bookingaccounts.forEach((b) => {
                    if (b.type == type) {
                         baList.push({ value: b.ID, text: b.description })
                    }
               })
          }
          return baList
     },
     bookingMonthList: (state) => {
          let startdate = moment(state.startbookingmonth)
          let todaydate = moment()
          let enddate = todaydate.add(6, 'months')
          let out = []
          for (
               let i = moment(startdate, 'YYYY-MM');
               i < moment(enddate, 'YYYY-MM');
               i = moment(i).add(1, 'months')
          ) {
               out.push({
                    value: moment(i).format('YYYY-MM'),
                    text: moment(i).format('YYYY-MM'),
               })
          }
          return out
     },
}
