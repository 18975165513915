export const namespaced = true

export const state = {
     screenloader: false,
}

export const mutations = {
     SET_SCREENLOADER(state, status) {
          //console.log(status)
          state.screenloader = status
     },
}
