<template>
     <div>
          <b-modal
               id="quickAddModal"
               v-b-modal.modal-sm
               class="p-3"
               hide-footer
               :title-html="modalTitle"
          >
               <div class="row" v-if="addForm == 'company'">
                    <div class="col-12">
                         <sInput v-model="company" :label="$t('company')" />
                    </div>
               </div>
               <div class="row" v-else-if="addForm == 'project'">
                    <div class="col-12">
                         <sInput v-model="project" :label="$t('project')" />
                    </div>
                    <div class="col-12">
                         <sSelect
                              v-model="companyid"
                              :label="$t('company')"
                              :options="companyList"
                         />
                    </div>
               </div>
               <div class="row" v-else-if="addForm == 'quote'">
                    <div class="col-12">
                         <sInput
                              v-model="project"
                              :label="$t('projecttitle')"
                         />
                    </div>
                    <div class="col-12">
                         <sSelect
                              v-model="companyid"
                              :label="$t('company')"
                              :options="companyList"
                         />
                    </div>
               </div>
               <div class="row" v-else-if="addForm == 'contact'">
                    <div class="col-12 mb-2">
                         <sSelect
                              v-model="companyid"
                              :label="$t('company')"
                              :options="companyList"
                         />
                    </div>
                    <div class="col-6">
                         <sInput v-model="firstname" :label="$t('firstname')" />
                    </div>
                    <div class="col-6">
                         <sInput v-model="lastname" :label="$t('lastname')" />
                    </div>
               </div>
               <div class="row" v-else-if="addForm == 'task'">
                    <div class="col-12">
                         <sInput v-model="task" :label="$t('task')" />
                    </div>
                    <div class="col-12">
                         <sSelect
                              v-model="projectid"
                              :label="$t('project')"
                              :options="projectList"
                         />
                    </div>
               </div>
               <div class="row" v-else-if="addForm == 'lead'">
                    <div class="col-12">
                         <b-form-group>
                              <b-form-radio-group
                                   v-model="lead.saleinitiative"
                                   :options="iniOptions"
                                   button-variant="secondary"
                              ></b-form-radio-group>
                         </b-form-group>
                    </div>
                    <div class="col-12 mb-2">
                         <sSelect
                              v-model="companyid"
                              :label="$t('company')"
                              :options="companyList"
                         />
                    </div>
                    <div class="col-sm-4">
                         <sSelect
                              v-model="lead.customerType"
                              :label="$t('customertype')"
                              :options="customerTypes"
                         />
                    </div>
                    <div class="col-sm-4">
                         <sSelect
                              v-model="lead.channelID"
                              :label="$t('channel')"
                              :options="channels"
                         />
                    </div>
                    <div class="col-sm-4">
                         <sInput
                              v-model="lead.offeredhourly"
                              type="number"
                              :label="$t('hourly')"
                              :append="'€ ' + $t('perhour')"
                         />
                    </div>
                    <div class="col-12">
                         <sTextarea
                              v-model="lead.comment"
                              :label="$t('comment')"
                         />
                    </div>
               </div>
               <div class="row" v-else-if="addForm == 'booking'">
                    <div class="col">
                         <div class="row">
                              <div class="col mb-4">
                                   <b-form-radio-group
                                        v-model="bookingType"
                                        :options="typeList"
                                        class="ml-4"
                                   ></b-form-radio-group>
                              </div>
                         </div>
                         <div class="row" v-show="bookingType">
                              <div class="col-12 mb-2">
                                   <sSelect
                                        v-model="bookaccountid"
                                        :label="$t('bookaccount')"
                                        :options="bookingAccountList"
                                   />
                              </div>
                              <div class="col-12 mb-4">
                                   <sSelect
                                        v-model="bookingmonth"
                                        :label="$t('bookingmonth')"
                                        :options="bookingMonthList"
                                   />
                              </div>
                              <div class="col-12 mb-2">
                                   <sSelect
                                        v-model="companyid"
                                        :label="$t('company')"
                                        :options="companyList"
                                   />
                              </div>
                              <div class="col-12 mb-4">
                                   <sInput
                                        v-model="booking"
                                        :label="$t('booking')"
                                   />
                              </div>
                              <div class="col-7 mb-2">
                                   <sInput
                                        v-model="netamount"
                                        :label="$t('netamount')"
                                        type="number"
                                        append="€"
                                   />
                              </div>
                              <div class="col-5 mb-2">
                                   <sInput
                                        v-model="tax"
                                        :label="$t('tax')"
                                        type="number"
                                        append="%"
                                   />
                              </div>
                         </div>
                    </div>
               </div>
               <div class="row" v-else-if="addForm == 'event'">
                    <div class="col">
                         <div class="row">
                              <div class="col-10 mb-2">
                                   <sSelect
                                        v-if="addBtn"
                                        v-model="projecttaskid"
                                        :label="$t('task')"
                                        :options="taskList"
                                   />

                                   <sInput
                                        v-else
                                        v-model="projecttask"
                                        :label="$t('task')"
                                   />
                              </div>
                              <div class="col-2">
                                   <b-button
                                        v-if="addBtn"
                                        @click="addBtn = false"
                                        ><font-awesome-icon icon="plus"
                                   /></b-button>
                                   <b-button v-else @click="addBtn = true"
                                        ><font-awesome-icon icon="list-ul"
                                   /></b-button>
                              </div>
                         </div>
                         <div class="row">
                              <div class="col">
                                   <sInput
                                        v-model="startdate"
                                        :label="$t('startdate')"
                                        type="date"
                                   />
                              </div>
                              <div class="col">
                                   <sInput
                                        v-model="starttime"
                                        :label="$t('starttime')"
                                        type="time"
                                   />
                              </div>
                         </div>
                         <div class="row">
                              <div class="col">
                                   <sInput
                                        v-model="enddate"
                                        :label="$t('enddate')"
                                        type="date"
                                   />
                              </div>
                              <div class="col">
                                   <sInput
                                        v-model="endtime"
                                        :label="$t('endtime')"
                                        type="time"
                                   />
                              </div>
                         </div>
                         <div class="row">
                              <div class="col-sm-6">
                                   <b-form-group>
                                        <b-form-radio-group
                                             :options="locationopts"
                                             v-model="location"
                                             class="pl-3 pt-2 pb-2"
                                        ></b-form-radio-group>
                                   </b-form-group>
                              </div>
                              <div class="col-sm-6">
                                   <div class="pl-3 pb-4">
                                        <toggle-button
                                             v-model="chargeOpt.value"
                                             :color="chargeOpt.bgColor"
                                             :labels="chargeOpt.label"
                                             :width="chargeOpt.width"
                                             :height="chargeOpt.height"
                                             class="ml-2"
                                             :font-size="chargeOpt.fontSize"
                                             :switch-color="
                                                  chargeOpt.switchColor
                                             "
                                        />
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
               <div class="row p-2">
                    <div class="col">
                         <b-button class="primBtn" @click="quickSave()">
                              {{ $t('save') }}
                         </b-button>
                    </div>
                    <div class="col" v-show="netamount && tax">
                         {{ $t('total') + ' ' + $t('gross') }}
                         <b class="bigfont">{{
                              currency(
                                   (Number(netamount) * Number(tax)) / 100 +
                                        Number(netamount)
                              )
                         }}</b>
                    </div>
               </div>
          </b-modal>
     </div>
</template>

<script>
import moment from 'moment'
import { currency, datetime } from '@/mixins/helpers.js'
import sInput from '@/components/formfields/InputField.vue'
import sSelect from '@/components/formfields/SelectField.vue'
import sTextarea from '@/components/formfields/TextArea.vue'
export default {
     name: 'quickadd-modal',
     mixins: [currency, datetime],
     components: {
          sInput,
          sSelect,
          sTextarea,
     },
     data() {
          return {
               addBtn: true,
               company: '',
               project: '',
               firstname: '',
               lastname: '',
               task: '',
               projectid: '',
               companyid: '',
               booking: '',
               bookingType: '',
               bookaccountid: '',
               bookingmonth: '',
               netamount: 0,
               location: 'home office',
               tax: 0,
               message: '',
               dSave: false,
               typeList: [
                    { value: 'Income', text: this.$t('income') },
                    { value: 'Outgoing', text: this.$t('outgoing') },
               ],
               startdate: '',
               starttime: '',
               enddate: '',
               endtime: '',
               locationopts: [
                    { value: 'home office', text: this.$t('homeoffice') },
                    { value: 'on-site customer', text: this.$t('onsitecust') },
                    {
                         value: 'on-site subcustomer',
                         text: this.$t('onsitesubcust'),
                    },
               ],
               chargeOpt: {
                    label: {
                         checked: this.$t('charge'),
                         unchecked: this.$t('nocharge'),
                    },
                    switchColor: { checked: 'white', unchecked: 'white' },
                    fontSize: 15,
                    bgColor: { checked: '#99e8cf', unchecked: '#e9ecef' },
                    color: { checked: 'white', unchecked: '#5a5a5a' },
                    width: 120,
                    height: 26,
                    value: false,
               },
               lead: {
                    saleinitiative: 'inquiry',
                    customerType: '',
                    channelID: '',
                    offeredhourly: null,
                    comment: '',
               },
               iniOptions: [
                    { text: this.$t('application'), value: 'application' },
                    { text: this.$t('inquiry'), value: 'inquiry' },
               ],
               customerTypes: [
                    { text: this.$t('agency'), value: 'agency' },
                    {
                         text: this.$t('directcustomer'),
                         value: 'direct customer',
                    },
                    { text: this.$t('headhunter'), value: 'headhunter' },
                    { text: this.$t('freelancer'), value: 'freelancer' },
                    {
                         text: this.$t('temporaryemploymentagency'),
                         value: 'temporary employment agency',
                    },
                    { text: this.$t('projectbroker'), value: 'project broker' },
               ],
          }
     },
     computed: {
          channels() {
               return this.$store.getters['sales/channelList']
          },
          taskList() {
               let tasks = []
               if (this.$store.getters['projects/taskList']) {
                    tasks = this.$store.getters['projects/taskList'].filter(
                         (t) => t.status != 'Done'
                    )
               }
               return tasks
          },
          projectList() {
               return this.$store.getters['projects/activeProjectList']
          },
          companyList() {
               return this.$store.getters['companies/companyList']
          },
          userID() {
               return this.$store.state.users.user.ID
          },
          addForm() {
               return this.$store.state.users.control.addForm
          },
          modalTitle() {
               let output = ''
               let icon = ''
               switch (this.addForm) {
                    case 'company':
                         output = this.$t('addcompany')
                         icon = 'building'
                         break
                    case 'contact':
                         output = this.$t('addcontact')
                         icon = 'user'
                         break
                    case 'task':
                         output = this.$t('addtask')
                         icon = 'task'
                         break
                    case 'project':
                         output = this.$t('addproject')
                         icon = 'project-diagram'
                         break
                    case 'quote':
                         output = this.$t('addquote')
                         icon = 'oney-check'
                         break
                    case 'booking':
                         output = this.$t('addbooking')
                         icon = 'balance-scale'
                         break
                    case 'lead':
                         output = this.$t('addlead')
                         icon = 'funnel-dollar'
                         break
               }
               return "<font-awesome-icon icon='" + icon + "' /> " + output
          },
          bookingAccountList() {
               let baL = this.$store.getters['bookings/bookingAccountList'](
                    this.bookingType
               )
               return baL
          },
          bookingMonthList() {
               return this.$store.getters['bookings/bookingMonthList']
          },
     },
     methods: {
          detailedSave() {
               this.dSave = true
               this.quickSave()
               this.dSave = false
          },
          quickSave() {
               switch (this.addForm) {
                    case 'project':
                         if (this.project == '' || this.companyid == '') {
                              this.$store.commit('messages/ADD_MESSAGE', {
                                   text: 'All fields are required!',
                                   icon: 'info',
                                   color: 'red',
                                   time: 5000,
                              })
                         } else {
                              var pData = {
                                   userID: this.userID,
                                   title: this.project,
                                   customer: this.companyid,
                              }
                              this.$store.dispatch(
                                   'projects/add_Project',
                                   pData
                              )
                              this.$bvModal.hide('quickAddModal')
                              this.$store.commit('messages/ADD_MESSAGE', {
                                   text:
                                        'New Project "' +
                                        this.project +
                                        '" saved sucessful!',
                                   icon: 'check',
                                   color: 'green',
                                   time: 5000,
                              })
                              this.project = ''
                              this.companyid == ''
                         }
                         break
                    case 'quote':
                         if (this.project == '' || this.companyid == '') {
                              this.$store.commit('messages/ADD_MESSAGE', {
                                   text: 'All fields are required!',
                                   icon: 'info',
                                   color: 'red',
                                   time: 5000,
                              })
                         } else {
                              var qData = {
                                   userID: this.userID,
                                   projecttitle: this.project,
                                   customerID: this.companyid,
                              }
                              this.$store.dispatch('quotes/add_Quote', qData)
                              this.$bvModal.hide('quickAddModal')
                              this.$store.commit('messages/ADD_MESSAGE', {
                                   text:
                                        'New Quote "' +
                                        this.quote +
                                        '" saved sucessful!',
                                   icon: 'check',
                                   color: 'green',
                                   time: 5000,
                              })
                              this.project = ''
                              this.companyid == ''
                         }
                         break
                    case 'contact':
                         if (
                              (!this.lastname && !this.lastname) ||
                              !this.companyid
                         ) {
                              this.$store.commit('messages/ADD_MESSAGE', {
                                   text: 'All fields are required!',
                                   icon: 'info',
                                   color: 'red',
                                   time: 5000,
                              })
                         } else {
                              var cData = {
                                   userID: this.userID,
                                   firstname: this.firstname,
                                   lastname: this.lastname,
                                   companyid: this.companyid,
                              }
                              this.$store.dispatch(
                                   'companies/add_Contact',
                                   cData
                              )
                              this.$bvModal.hide('quickAddModal')
                              this.firstname = ''
                              this.lastname = ''
                              this.companyid == ''
                              if (this.dSave) {
                                   let cID =
                                        this.$store.state.companies
                                             .lastSavedContact
                                   this.$router.push({
                                        path: `/contact/${cID}`,
                                   })
                              }
                         }
                         break
                    case 'task':
                         if (this.task == '' || this.projectid == '') {
                              this.$store.commit('messages/ADD_MESSAGE', {
                                   text: 'All fields are required!',
                                   icon: 'info',
                                   color: 'red',
                                   time: 5000,
                              })
                         } else {
                              var tData = {
                                   task: this.task,
                                   projectid: this.projectid,
                                   userID: this.userID,
                              }
                              this.$store.dispatch('projects/add_Task', tData)
                              this.$bvModal.hide('quickAddModal')
                              this.task = ''
                              this.projectid == ''
                         }
                         break
                    case 'company':
                         if (this.company == '') {
                              this.$store.commit('messages/ADD_MESSAGE', {
                                   text: 'All fields are required!',
                                   icon: 'info',
                                   color: 'red',
                                   time: 5000,
                              })
                         } else {
                              var cpData = {
                                   company: this.company,
                                   userID: this.userID,
                              }
                              this.$store.dispatch(
                                   'companies/add_Company',
                                   cpData
                              )
                              this.$bvModal.hide('quickAddModal')
                              this.company = ''
                         }
                         break
                    case 'event':
                         if (
                              this.projecttaskid == '' &&
                              this.projecttask == ''
                         ) {
                              this.$store.commit('messages/ADD_MESSAGE', {
                                   text: 'All fields are required!',
                                   icon: 'info',
                                   color: 'red',
                                   time: 5000,
                              })
                         } else {
                              var eData = {
                                   projecttaskid: this.projecttaskid,
                                   userID: this.userID,
                              }
                              this.$store.dispatch(
                                   'companies/add_Company',
                                   eData
                              )
                              this.$bvModal.hide('quickAddModal')
                              this.company = ''
                         }
                         break
                    case 'booking':
                         if (
                              this.booking == '' ||
                              this.netamount == '' ||
                              this.bookaccountid == '' ||
                              this.bookingmonth == '' ||
                              this.companyid == ''
                         ) {
                              this.$store.commit('messages/ADD_MESSAGE', {
                                   text: 'All fields are required!',
                                   icon: 'info',
                                   color: 'red',
                                   time: 5000,
                              })
                         } else {
                              let bData = {
                                   description: this.booking,
                                   netamount: this.netamount,
                                   bookaccountid: this.bookaccountid,
                                   bookingmonth: this.bookingmonth,
                                   tax: this.tax,
                                   companyid: this.companyid,
                              }
                              this.$store.dispatch(
                                   'bookings/add_Booking',
                                   bData
                              )
                              this.$bvModal.hide('quickAddModal')
                              this.booking = ''
                              this.netamount = ''
                         }
                         break
                    case 'lead':
                         if (
                              this.lead.channelID == '' ||
                              this.lead.customerType == '' ||
                              this.lead.offeredhourly == '' ||
                              this.lead.saleinitiative == '' ||
                              this.companyid == ''
                         ) {
                              this.$store.commit('messages/ADD_MESSAGE', {
                                   text: 'All fields are required!',
                                   icon: 'info',
                                   color: 'red',
                                   time: 5000,
                              })
                         } else {
                              var sData = {
                                   userID: this.userID,
                                   companyID: this.companyid,
                                   salesmonth: moment().format('YYYY-MM'),
                                   initiative: this.lead.saleinitiative,
                                   customertype: this.lead.customerType,
                                   channelID: this.lead.channelID,
                                   offeredhourly: this.lead.offeredhourly,
                                   comment: this.lead.comment,
                              }
                              this.$store.dispatch('sales/add_Sale', sData)
                              this.$bvModal.hide('quickAddModal')
                              this.$store.commit('messages/ADD_MESSAGE', {
                                   text:
                                        'New Sale "' +
                                        this.sale +
                                        '" saved sucessful!',
                                   icon: 'check',
                                   color: 'green',
                                   time: 5000,
                              })
                              this.sale = ''
                              this.companyid == ''
                         }
                         break
               }
          },
     },
     created() {
          this.bookingmonth = moment().format('YYYY-MM')
          this.startdate = moment().format('YYYY-MM-DD')
          this.enddate = moment().format('YYYY-MM-DD')
          this.starttime = moment().format('HH:mm:ss')
          this.endtime = moment().format('HH:mm:ss')
     },
}
</script>

<style lang="scss">
.primBtn {
     background-color: #67caac !important;
     border: 1px solid #67caac !important;
}
</style>
