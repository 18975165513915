<template>
     <div id="messagebox">
          <transition-group name="flow">
               <div
                    v-for="(message, index) in messages"
                    :key="index"
                    class="messagetask"
                    :style="'background-color: ' + message.color"
               >
                    <div class="row">
                         <div class="col-2">
                              <font-awesome-icon
                                   style="
                                        color: white;
                                        font-size: 1.7em;
                                        margin-bottom: 0.3rem;
                                   "
                                   :icon="message.icon"
                              />
                         </div>
                         <div class="col-10">
                              <p>{{ message.text }}</p>
                         </div>
                    </div>
               </div>
          </transition-group>
     </div>
</template>

<script>
export default {
     name: 'message-box',
     data() {
          return {
               color: '',
          }
     },
     computed: {
          messages() {
               return this.$store.state.messages.messages
          },
     },
     mounted() {},
}
</script>

<style lang="sass">
@import '@/assets/SASS/main.sass'
#messagebox
    position: fixed
    top: 12vh
    right: 5%
    height: auto
    width: 40%
    max-width: 300px
    z-index: 1050
    @media #{$smartphone}
        top: auto
        bottom: 40vh
        left: 50%
        transform: translateX(-50%)
        width: 70%
    .messagetask
        width: 100%
        min-height: 35px
        padding: 1rem 10%
        border-radius: 6px
        margin-bottom: 0.6rem
        p
            font-weight: bold
            font-size: 1rem
            margin: 0
            padding: 0
</style>
