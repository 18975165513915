import dbService from '@/services/dbService.js'
import basicFunctions from '@/services/basicFunctions.js'
import router from '@/router'
//import { data } from 'jquery'

export const namespaced = true

export const state = {
     user: {},
     userstatus: false,
     timetracker: {
          maximized: false,
          task: '',
          controlIcon: 'play',
     },
     control: {
          addForm: '',
     },
}

export const mutations = {
     SET_USERDATA(state, data) {
          state.user = data
     },
     SET_USERSTATUS(state, data) {
          state.userstatus = data
     },
     SET_ADDFORM(state, data) {
          state.control.addForm = data
     },
     SET_TT_TASK(state, data) {
          state.timetracker.task = data
     },
     SET_TT_MAXIMIZED(state, data) {
          state.timetracker.maximized = data
     },
     SET_TT_CONTROL(state, data) {
          state.timetracker.controlIcon = data
     },
     CLEAR_USERDATA(state) {
          state.user = {}
     },
     CLEAR_TT(state) {
          state.timetracker.task = ''
          state.timetracker.maximized = false
          state.timetracker.controlIcon = 'play'
     },
}

export const actions = {
     check_Access({ commit, dispatch }) {
          if (localStorage.getItem('user')) {
               let userData = JSON.parse(localStorage.getItem('user'))
               if (userData.userID && userData.token) {
                    userData.query = 'checkAccess'
                    return dbService
                         .runQuery(userData)
                         .then((response) => {
                              if (
                                   response.status != 200 ||
                                   response.data == 'Not found'
                              ) {
                                   dispatch('logout')
                                   return 'Logout'
                              } else {
                                   commit('usability/SET_SCREENLOADER', true, {
                                        root: true,
                                   })
                                   dispatch('init_Data', {}, { root: true })

                                   commit('SET_USERSTATUS', true)

                                   setTimeout(() => {
                                        commit(
                                             'usability/SET_SCREENLOADER',
                                             false,
                                             {
                                                  root: true,
                                             },
                                             2000
                                        )
                                   })
                                   return 'Online'
                              }
                         })
                         .catch((error) => {
                              console.log(error)
                         })
               } else {
                    commit('SET_USERSTATUS', false)
               }
          } else {
               commit('SET_USERSTATUS', false)
          }
     },
     check_locale({ state }) {
          let locale = ''
          if (state.user.language) {
               localStorage.setItem('locale', state.user.language)
               locale = state.user.language
          } else {
               if (localStorage.getItem('locale')) {
                    locale = localStorage.getItem('locale')
               } else {
                    localStorage.setItem('locale', navigator.language)
                    locale = navigator.language
               }
          }
          return locale
     },
     login({ commit, dispatch }, data) {
          data.query = 'checkLogin'
          return dbService
               .runQuery(data)
               .then((response) => {
                    console.log(response)
                    if (
                         response.status == 200 &&
                         response.data != 'Not found'
                    ) {
                         let userdata = response.data[0]
                         let message = {}
                         switch (userdata.status) {
                              case 'N':
                                   message = {
                                        text: "Sorry, but this account isn't verified! Please verify your account first!",
                                        icon: 'info',
                                        color: 'red',
                                        time: 6000,
                                   }
                                   dispatch('messages/add_Message', message, {
                                        root: true,
                                   })
                                   break
                              case 'A':
                                   commit('SET_USERDATA', userdata)
                                   dispatch('set_Session', userdata.ID)
                                   dispatch('init_Data', {}, { root: true })
                                   router.push({ path: '/dashboard' })
                                   break
                              default:
                                   message = {
                                        text: "Sorry, but we couldn't find an active account.",
                                        icon: 'info',
                                        color: 'red',
                                        time: 6000,
                                   }
                                   dispatch('messages/add_Message', message, {
                                        root: true,
                                   })
                                   break
                         }
                    } else {
                         let message = {
                              text: 'Thils email/password combination does not exist! Please try it again!',
                              icon: 'info',
                              color: 'red',
                              time: 5000,
                         }
                         dispatch('messages/add_Message', message, {
                              root: true,
                         })
                    }
               })
               .catch((error) => {
                    console.log(error)
               })
     },
     logout({ commit, dispatch }) {
          let userData = JSON.parse(localStorage.getItem('user'))
          if (userData.userID) {
               dispatch('delete_Session', userData)
          }
          localStorage.removeItem('user')
          commit('CLEAR_USERDATA')
          commit('projects/CLEAR_PROJECTS', {}, { root: true })
          commit('projects/CLEAR_TASKS', {}, { root: true })
          commit('projects/CLEAR_TIMES', {}, { root: true })
          commit('companies/CLEAR_COMPANIES', {}, { root: true })
          commit('companies/CLEAR_CONTACTS', {}, { root: true })
          commit('bookings/CLEAR_BOOKINGS', {}, { root: true })
          commit('CLEAR_TT')
          router.push({ path: '/' })
     },
     delete_Session(state, data) {
          data.query = 'deleteSession'
          return dbService.runQuery(data).catch((error) => {
               console.log(error)
          })
     },
     set_Session({ dispatch }, userID) {
          let token = basicFunctions.generate_token(20)
          let data = { userID: userID, token: token, query: 'addSession' }
          return dbService
               .runQuery(data)
               .then((response) => {
                    console.log(response)
                    if (response.status == 200 && response.data == 'Success') {
                         let userData = JSON.stringify({
                              userID: data.userID,
                              token: data.token,
                         })
                         console.log(userData)
                         localStorage.setItem('user', userData)
                    } else {
                         let message = {
                              text: 'Sorry, but anything went wrong during the login process. Please try it again later.',
                              icon: 'info',
                              color: 'red',
                              time: 6000,
                         }
                         dispatch('messages/add_Message', message, {
                              root: true,
                         })
                    }
               })
               .catch((error) => {
                    console.log(error)
               })
     },
     email_exists(state, data) {
          data.query = 'emailExists'
          return dbService
               .runQuery(data)
               .then((response) => {
                    return response.data
               })
               .catch((error) => {
                    console.log(error)
               })
     },
     register(state, data) {
          data.query = 'register'
          return dbService
               .runQuery(data)
               .then((response) => {
                    return response.data
               })
               .catch((error) => {
                    console.log(error)
               })
     },
     get_UserInfo({ commit }, data) {
          data.query = 'getUserInfo'
          return dbService
               .runQuery(data)
               .then((response) => {
                    if (
                         response.status == 200 &&
                         response.data != 'Not found'
                    ) {
                         commit('SET_USERDATA', response.data)
                    }
               })
               .catch((error) => {
                    console.log(error)
               })
     },
     get_settings(state) {
          let data = {}
          data.userID = state.user.ID
          return dbService.getSettings(data)
     },
     update_settings(state, data) {
          return dbService.updateSettings(data)
     },
}

export const getters = {
     getProgressStatus: (state, getters, rootState) => {
          let active = {}
          active.value = 0
          if (!rootState.companies.companiesdata) {
               active.value = 1
               active.name = 'company'
          } else {
               if (!rootState.projects.projectsdata) {
                    active.value = 2
                    active.name = 'project'
               } else {
                    if (!rootState.projects.tasksdata) {
                         active.value = 3
                         active.name = 'task'
                    } else {
                         if (!rootState.projects.timesdata) {
                              active.value = 4
                              active.name = 'time'
                         } else {
                              active.value = 5
                         }
                    }
               }
          }
          return active
     },
     getUserData: (state) => {
          return state.user
     },
}
