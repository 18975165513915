<template>
     <div>
          <div id="selectfield">
               <label :class="labelStatus ? 'active' : ''">{{ label }}</label>
               <select
                    class="selectfield"
                    v-model="inputVal"
                    type="text"
                    :disabled="disabled"
                    @focus="focusLabel"
                    @blur="blurLabel"
               >
                    <option
                         v-for="(option, index) in options"
                         :key="index"
                         :value="option.value"
                    >
                         {{ option.text }}
                    </option>
               </select>
               <div v-show="del && inputVal" class="del" @click="delInput">
                    <font-awesome-icon icon="ban" />
               </div>
               <div v-show="checkLink" class="link" @dblclick="goTo">
                    <font-awesome-icon icon="search" />
               </div>
          </div>
     </div>
</template>

<script>
export default {
     name: 'select-field',
     props: {
          label: String,
          value: String,
          options: Array,
          disabled: {
               type: Boolean,
               default: false,
          },
          del: {
               type: Boolean,
               default: false,
          },
          link: {
               type: String,
               default: '',
          },
     },
     data: function () {
          return {
               labelStatus: false,
          }
     },
     computed: {
          inputVal: {
               get() {
                    return this.value
               },
               set(val) {
                    this.$emit('input', val)
               },
          },
          checkLink() {
               if (this.link && this.inputVal) {
                    return true
               } else {
                    return false
               }
          },
     },
     methods: {
          focusLabel() {
               this.labelStatus = true
          },
          blurLabel() {
               this.checkInput()
          },
          checkInput() {
               if (this.value) {
                    this.labelStatus = true
               } else {
                    this.labelStatus = false
               }
          },
          delInput() {
               this.inputVal = null
          },
          goTo() {
               return this.$router.push({
                    path: `${this.link}${this.inputVal}`,
               })
          },
     },
     created() {
          this.checkInput()
     },
}
</script>

<style lang="sass" scoped>
@import '@/assets/SASS/main.sass'
#selectfield
     display: inline-block
     position: relative
     background-color: white
     height: 2.5rem
     width: 100%

     label
          position: absolute
          top: 0.45rem
          left: 1rem
          font-size: 0.9rem
          transition: 0.6s
          color: #AAA
          letter-spacing: 0.05rem

          &.active
               top: -0.5rem
               font-size: 0.7rem
               z-index: 10
               transition: 0.6s
               background-color: white
               padding: 0 5px
               width: auto
               color: #8dd7c1
     .selectfield
          position: absolute
          top: 0
          left: 0
          background-color: rgba(255,255,255,0)
          border-top: 1px solid #DDD
          border-right: 1px solid #DDD
          border-left: 1px solid #DDD
          border-bottom: 1px solid #CCC
          padding: 0.55rem 1rem
          border-radius: 6px
          width: 100%
          font-size: 1rem
          &:focus
               outline-color: $primarycolor
          option
               line-height: 1.4rem
               &:checked
                    background-color: lighten($primarycolor, 20%)
               &:hover
                    background-color: lighten($primarycolor, 20%) !important
     .del
          position: absolute
          top: 9px
          right: 25px
          background-color: lighten($red, 15%)
          color: white
          font-size: 0.65rem
          padding: 0.15rem 0.285rem
          border-radius: 50%
          width: 1.2rem
          height: 1.2rem
     .link
          position: absolute
          top: 8px
          left: -9px
          background-color: $lightgrey
          color: white
          font-size: 0.8rem
          padding: 0.2rem 0.325rem
          border-radius: 50%
          width: 1.5rem
          height: 1.5rem
</style>
