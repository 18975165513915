<template>
     <div class="container stopbox">
          <div class="stopwatch" :class="stopwatchDynamic" v-show="task != ''">
               <div class="closer" @click="maxToggle">
                    <font-awesome-icon
                         icon="times-circle"
                         v-if="timetracker.maximized"
                    />
               </div>

               <div class="displaybox">
                    <h3>Time Tracker</h3>
                    <p><font-awesome-icon icon="tasks" /> {{ task.title }}</p>
                    <div class="stoptimedisplay">
                         {{ displayHours }}:{{ displayMinutes }}:{{
                              displaySeconds
                         }}
                    </div>
                    <div class="row locationbox">
                         <div class="col mt-3 mb-0">
                              <b-form-radio-group
                                   v-model="location"
                                   :options="locationoptions"
                                   button-variant="secondary"
                                   :disabled="disable"
                              ></b-form-radio-group>
                         </div>
                    </div>
                    <div class="row">
                         <!-- <div class="col-md-8">
                        <b-form-select
                            v-model="timetracker.task"
                            :options="tasks"
                            size="sm"
                            class="tasklist"
                            :disabled="disable"
                        ></b-form-select>
                    </div> -->
                         <div class="col-md-4 mb-2">
                              <transition name="blob">
                                   <div
                                        class="stopwatchcontrol"
                                        v-if="timetracker.task"
                                   >
                                        <font-awesome-icon
                                             :icon="timetracker.controlIcon"
                                             @click="toggleControl"
                                        />
                                   </div>
                              </transition>
                         </div>
                    </div>
               </div>
          </div>
     </div>
</template>

<script>
import moment from 'moment'
import dbService from '@/services/dbService.js'
import basicFunctions from '@/services/basicFunctions.js'
export default {
     name: 'stoppwatch-form',
     data() {
          return {
               location: 'home office',
               locationoptions: [
                    'home office',
                    'on-site customer',
                    'on-site subcustomer',
               ],
               timeID: '',
               startTime: moment(),
               diffSeconds: 0,
               seconds: 0,
               minutes: 0,
               hours: 0,
               timerS: null,
               timerM: null,
               disable: false,
          }
     },
     computed: {
          task() {
               let task = []
               if (
                    this.$store.getters['projects/task'](this.timetracker.task)
               ) {
                    task = this.$store.getters['projects/task'](
                         this.timetracker.task
                    )
               }
               return task
          },
          displayHours() {
               return this.prepareDisplayTime(this.hours)
          },
          displayMinutes() {
               return this.prepareDisplayTime(this.minutes)
          },
          displaySeconds() {
               return this.prepareDisplayTime(this.seconds)
          },
          userID() {
               return this.$store.state.users.user.ID
          },
          timetracker() {
               return this.$store.state.users.timetracker
          },
          stopwatchDynamic() {
               let max = ''
               let tick = ''
               if (this.timetracker.maximized) {
                    max = 'maximized'
               }
               if (
                    !this.timetracker.maximized &&
                    this.timetracker.controlIcon == 'stop'
               ) {
                    tick = 'tick'
               }
               return (max + ' ' + tick).trim()
          },
     },
     methods: {
          maxToggle() {
               this.timetracker.maximized
                    ? this.$store.commit('users/SET_TT_MAXIMIZED', false)
                    : this.$store.commit('users/SET_TT_MAXIMIZED', true)
          },
          toggleControl() {
               if (this.timetracker.controlIcon == 'play') {
                    this.$store.commit('users/SET_TT_CONTROL', 'stop')
                    this.startTime = moment()
                    localStorage.setItem('stopWatch_start', this.startTime)
                    this.saveStartTime()
                    this.disable = true
                    this.$store.dispatch('projects/get_All', {})
                    this.timerS = setInterval(() => {
                         let duration = moment() - moment(this.startTime)
                         this.diffSeconds = (duration / 1000).toFixed(0)
                         this.secondsToTime()
                    }, 1000)
                    this.timerM = setInterval(() => {
                         this.saveEndTime()
                    }, 60000)
               } else {
                    this.$store.commit('users/SET_TT_CONTROL', 'play')
                    clearInterval(this.timerS)
                    clearInterval(this.timerM)
                    this.saveEndTime()
                    this.$store.dispatch('projects/get_All', {})
                    this.$store.commit('messages/ADD_MESSAGE', {
                         text:
                              this.displayHours +
                              ':' +
                              this.displayMinutes +
                              ':' +
                              this.displaySeconds +
                              ' saved in database!',
                         icon: 'check',
                         color: 'green',
                         time: 5000,
                    })
                    this.disable = false
                    /* this.seconds = 0
                this.minutes = 0
                this.hours = 0 */
               }
          },
          saveStartTime() {
               this.timeID = basicFunctions.generate_token(6)
               let starttime = moment(this.starttime).format(
                    'YYYY-MM-DD HH:mm:ss'
               )
               let data = {
                    taskid: this.timetracker.task,
                    starttime: starttime,
                    location: this.location,
                    ID: this.timeID,
                    query: 'setStartTime',
               }
               dbService
                    .runQuery(data)
                    .then((response) => {
                         console.log(response)
                    })
                    .catch((error) => {
                         console.log(error)
                    })
          },
          saveEndTime() {
               let data = {
                    ID: this.timeID,
                    endtime: moment().format('YYYY-MM-DD HH:mm:ss'),
                    query: 'setEndTime',
               }
               dbService
                    .runQuery(data)
                    .then(() => {
                         this.$store.dispatch('projects/getAll', {})
                    })
                    .catch((error) => {
                         console.log(error)
                    })
          },
          secondsToTime() {
               let hours = Math.floor(this.diffSeconds / (60 * 60))
               let divisor_for_minutes = this.diffSeconds % (60 * 60)
               let minutes = Math.floor(divisor_for_minutes / 60)
               let divisor_for_seconds = divisor_for_minutes % 60
               let seconds = Math.ceil(divisor_for_seconds)
               this.hours = hours
               this.minutes = minutes
               this.seconds = seconds
          },
          prepareDisplayTime(value) {
               let unit = ''
               if (value < 10) {
                    unit = '0' + value
               } else {
                    unit = value
               }
               return unit
          },
     },
}
</script>

<style lang="sass">
@import '@/assets/SASS/main.sass'
.stopbox
    position: relative
    .stopwatch
        @include logoshape
        width: 70px
        height: 45px
        background-color: lighten($lightgrey, 15%)
        position: absolute
        top: 1.8rem
        right: 0
        transition: 1s
        transform-origin: right top
        &.tick
            animation-name: tick
            animation-duration: 1s
            animation-iteration-count: infinite
            background-color: lighten($primarycolor, 25%)
        &.maximized
            z-index: 1000
            position: absolute
            top: 30vh
            right: -80%
            width: 70vw
            height: 45vw
            max-width: 700px
            height: 450px
            opacity: 0.8
            @media #{$smartphone}
                width: 100vw
                height: 120vw
                right: -90%
            @media #{$tablet}
                right: -70%
            background-color: lighten($primarycolor, 10%)
            transition: 1s
            .closer
                display: block
                width: auto
                height: auto
                transition: 1s
                opacity: 1
            .displaybox
                top: 40%
                h3
                    display: block
                    transition: 1.5s
                    opacity: 1
                p
                    display: block
                    transition: 1.5s
                    opacity: 1
                .stoptimedisplay
                    font-size: 4rem
                    transition: 1.5s
                    color: #646464
                    @media #{$smartphone}
                        font-size: 1.5rem
                    @media #{$tablet}
                        font-size: 2.2rem
                .locationbox
                    display: block
                    transition: 1.5s
                    opacity: 1
                    margin-top: 3%
                    @media #{$smartphone}
                        margin-top: 1%
                .tasklist
                    display: block
                    transition: 1.5s
                    opacity: 1
                    margin-top: 3%
                    @media #{$smartphone}
                        margin-top: 1%
                .stopwatchcontrol
                    margin-top: 5%
                    @media #{$smartphone}
                        margin-top: 2%
                    display: block
                    transition: 1.5s
                    opacity: 1
        .closer
            position: absolute
            top: 0
            right: 0
            font-size: 2.5rem
            @media #{$smartphone}
                font-size: 1.8rem
            width: 100%
            height: 100%
            transition: 1s
            opacity: 0
        .displaybox
            position: absolute
            top: 58%
            left: 50%
            transform: translate(-50%,-40%)
            width: 60%
            height: 60%
            @media #{$smartphone}
                height: auto
            text-align: center
            text-align: left
            h3
                font-size: 2rem
                text-transform: uppercase
                color: white
                margin: 0.2rem auto
                display: none
                transition: 1.5s
                opacity: 0
                @media #{$smartphone}
                    font-size: 1.2rem
            p
                color: white
                opacity: 0
                transition: 1.5s
                display: none
                background-color: $primarycolor
                padding: 4px 10px
                border-radius: 8px
                svg
                    font-size: 0.8rem
                    margin-right: 5px
            .locationbox
                display: none
                transition: 1s
                position: relative
                opacity: 0
                .custom-control-label
                    color: white
                .custom-control-input:checked~.custom-control-label:before
                    border-color: white
                    background-color: darken($primarycolor, 20%)
            .stoptimedisplay
                font-size: 0.5rem
                color: $lightgrey
                font-family: 'Digital', Arial
                text-align: center
                background-color: white
                width: 100%
                border-radius: 15px
                transition: 1s
                position: relative
            .tasklist
                width: 100%
                display: none
                opacity: 1
                transition: 1s
                position: relative
                @media #{$smartphone}
                    top: 50%
            .stopwatchcontrol
                width: 100%
                height: 2.5rem
                font-size: 2.4rem
                display: none
                opacity: 1
                transition: 1s
                position: relative
                @media #{$tablet}
                    font-size: 2rem
                @media #{$smartphone}
                    font-size: 1.6rem
                    top: 70%
                svg
                    position: absolute
                    top: 50%
                    left: 50%
                    transform: translate(-50%, -50%)
                    color: darken($primarycolor, 25%)
</style>
