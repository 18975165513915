import dbService from '@/services/dbService.js'
import basicFunctions from '@/services/basicFunctions.js'

export const namespaced = true

export const state = {
     salesdata: [],
     channelsdata: [],
}

export const mutations = {
     FILL_SALESDATA(state, data) {
          state.salesdata = data
     },
     FILL_CHANNELSDATA(state, data) {
          state.channelsdata = data
     },
}

export const actions = {
     get_Sales({ commit }, data) {
          data.query = 'getSales'
          return dbService
               .runQuery(data)
               .then((response) => {
                    if (
                         response.status == 200 &&
                         response.data != 'Not found'
                    ) {
                         commit('FILL_SALESDATA', response.data)
                    }
               })
               .catch((error) => {
                    console.log(error)
               })
     },
     get_Channels({ commit }, data) {
          data.query = 'getChannels'
          return dbService
               .runQuery(data)
               .then((response) => {
                    if (
                         response.status == 200 &&
                         response.data != 'Not found'
                    ) {
                         commit('FILL_CHANNELSDATA', response.data)
                    }
               })
               .catch((error) => {
                    console.log(error)
               })
     },
     add_Sale({ dispatch, commit }, data) {
          data.ID = basicFunctions.generate_token(10)
          data.query = 'addSale'
          return dbService
               .runQuery(data)
               .then((response) => {
                    if (response.status != 200) {
                         commit(
                              'messages/ADD_MESSAGE',
                              {
                                   text: 'Sorry! Something went wront, so we could not save the sale. Please try it again.',
                                   icon: 'info',
                                   color: 'red',
                                   time: 5000,
                              },
                              { root: true }
                         )
                    } else {
                         dispatch('get_Sales', {})
                    }
               })
               .catch((error) => {
                    console.log(error)
               })
     },
     update_SalesLevel({ dispatch }, data) {
          data.query = 'updateSalesLevel'
          return dbService
               .runQuery(data)
               .then((response) => {
                    if (
                         response.status == 200 &&
                         response.data != 'Not found'
                    ) {
                         dispatch('get_Sales', {})
                    }
               })
               .catch((error) => {
                    console.log(error)
               })
     },
     update_Sale({ dispatch, commit }, data) {
          data.query = 'updateSale'
          return dbService
               .runQuery(data)
               .then((response) => {
                    if (response.status != 200) {
                         commit(
                              'messages/ADD_MESSAGE',
                              {
                                   text: 'Sorry! Something went wront, so we could not save the sale. Please try it again.',
                                   icon: 'info',
                                   color: 'red',
                                   time: 5000,
                              },
                              { root: true }
                         )
                         return 'FAILED'
                    } else {
                         dispatch('get_All', {})
                         commit(
                              'messages/ADD_MESSAGE',
                              {
                                   text: 'Sale saved sucessful!',
                                   icon: 'check',
                                   color: 'green',
                                   time: 5000,
                              },
                              { root: true }
                         )
                         return 'OK'
                    }
               })
               .catch((error) => {
                    console.log(error)
               })
     },
}

export const getters = {
     sales: (state) => {
          let sales = []
          if (state.salesdata) {
               sales = state.salesdata
          }
          sales.forEach((s) => {
               s.show = ''
          })
          return sales
     },
     sale: (state) => (ID) => {
          let sale = {}
          if (state.salesdata) {
               sale = state.salesdata.find((s) => s.ID == ID)
          }
          return sale
     },
     companysales: (state) => (ID) => {
          let sales = []
          if (state.salesdata) {
               sales = state.salesdata.filter((s) => s.companyID == ID)
          }
          return sales
     },
     channelList: (state) => {
          let channelList = []
          if (state.channelsdata) {
               state.channelsdata.forEach((c) => {
                    channelList.push({ value: c.ID, text: c.channel })
               })
          }
          return channelList
     },
     channel: (state) => (ID) => {
          let channel = {}
          if (state.channelsdata) {
               channel = state.salesdata.find((c) => c.ID == ID)
          }
          return channel
     },
}
