export const currency = {
     methods: {
          gross: function (amount, percent) {
               let gross =
                    (Number(amount) / 100) * Number(percent) + Number(amount)
               return gross
          },
          percentamount: function (amount, percent) {
               let percentamount = (Number(amount) / 100) * Number(percent)
               return percentamount
          },
          currency: function (amount) {
               let output
               output = Number(amount).toFixed(2) + '€'
               return output
          },
     },
}

export const listOps = {
     methods: {
          sortList(field, data, direction) {
               // direction true = asc / false = desc
               let output = data.sort((a, b) => {
                    let valA = a[field].toLowerCase()
                    let valB = b[field].toLowerCase()
                    if (valA == valB) {
                         return 0
                    } else if (
                         (valA < valB && !direction) ||
                         (valA > valB && direction)
                    ) {
                         return 1
                    } else {
                         return -1
                    }
               })
               return output
          },
          sortByField(list, fieldName, ascending = true) {
               let l = list.sort((a, b) => {
                    const fieldA = a[fieldName]
                    const fieldB = b[fieldName]

                    if (ascending) {
                         return fieldA < fieldB ? -1 : fieldA > fieldB ? 1 : 0
                    } else {
                         return fieldB < fieldA ? -1 : fieldB > fieldA ? 1 : 0
                    }
               })
               return l
          },
     },
}

export const datetime = {
     methods: {
          localDate: function (value, local) {
               let date = ''
               if (value) {
                    switch (local) {
                         case 'de':
                              if (value != '0000-00-00') {
                                   date =
                                        value.substr(8, 2) +
                                        '.' +
                                        value.substr(5, 2) +
                                        '.' +
                                        value.substr(0, 4)
                              } else {
                                   date = '-'
                              }
                              break
                    }
               }

               return date
          },
          shortDate: function (value) {
               let shortdate, q
               if (value != '0000-00') {
                    q = (value.substr(5, 2) / 3).toFixed()
                    shortdate =
                         value.substr(5, 2) +
                         '/' +
                         value.substr(0, 4) +
                         ' (Q' +
                         q +
                         ')'
               } else {
                    shortdate = '-'
               }
               return shortdate
          },
     },
}
